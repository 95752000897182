/* eslint-disable no-useless-concat */
import { gql, useQuery } from "@apollo/client";
import { styled } from '@mui/material/styles';
import { Edit } from "@mui/icons-material";
import { Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { ORGANIZATION } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import PhoneNumberActions from "../HOC/CustomComponents/PhoneNumberActions";


const PREFIX = 'OrganizationDataView';

const classes = {
  box: `${PREFIX}-box`,
  main: `${PREFIX}-main`,
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const OrganizationDataView = (props) => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${ORGANIZATION.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: {},
    }
  );


  const user = Globals.user;

  const canEdit =
    user.hasPermission("core.organization.update");

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/organization/edit`),
      icon: Edit,
      permission: canEdit,
    },
  ]

  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading >
    :
    <StyledGrid container justifyContent="center" sx={{ margin: "0", width: "100%" }}>
      <>
        <TitleAppBar path={props.match.path} >
          <LongMenu icons={icons} />
        </TitleAppBar>

        <Grid
          container
          justifyContent="center"
          sx={{ width: "100%", p: 2, gap: 2 }}
        >
          <Paper container className={classes.box} component={Grid}>
            <KeyValuePair title={t("organizationName")} value={data?.organization.name} />
            <KeyValuePair title={t("ownerName")} value={data?.organization.owner} />
            <KeyValuePair title={t("activity")} value={data?.organization.activity} />
            <KeyValuePair title={t("registrationNumber")} value={data?.organization.registrationNumber} />
            <KeyValuePair noDefaultStyle valueStyle={{ "inlineSize": "max-content" }} dir="ltr" title={t("mobile")} value={<PhoneNumberActions phone={data?.organization.phone} />} />
            <KeyValuePair title={t("email")} value={data?.organization.email} />
            <KeyValuePair title={t("website")} value={data?.organization.website} />
            <KeyValuePair title={t("taxArea")} value={data?.organization.taxArea} />
            <KeyValuePair title={t("taxNumber")} value={data?.organization.taxNumber} />
            <KeyValuePair title={t("taxActivityCode")} value={data?.organization.taxActivityCode} />
            <KeyValuePair title={t("taxClientId")} value={data?.organization.taxClientId} />
            <KeyValuePair title={t("taxClientSecret")} value={data?.organization.taxClientSecret} />
            <KeyValuePair title={t("reportHeader")} value={data?.organization.reportHeader} />
            <KeyValuePair title={t("reportFooter")} value={data?.organization.reportFooter} />
          </Paper>
        </Grid>
      </>
    </StyledGrid>
};

export default OrganizationDataView;
