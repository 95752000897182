import {
  IconButton,
  ListItemIcon,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";
import { useAppSelector } from "../../../store/store";
import { useDispatch } from "react-redux";
import { updateDarkMode } from "../../../store/slices/preferences";
import { Globals } from "../Classes/Globals";
import { useTranslation } from "react-i18next";
import { updateDarkModeAsync } from "../../../store/thunk/preferencesThunks";


const DarkModeIcon = (props) => {
  const { listItem } = props
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const darkMode = useAppSelector((state) => state.preferences.darkMode);

  const handleToggle = () => {
    let newMode
    newMode = darkMode.value === "dark" ? "light" : "dark"

    dispatch(updateDarkMode({
      id: darkMode.id,
      value: newMode
    }))

    if (Globals?.user) {
      dispatch(updateDarkModeAsync(
        {
          id: darkMode.id,
          value: newMode
        }
      ))
    }
  }

  const IconButtonData = <IconButton
    aria-label="print"
    onClick={handleToggle}
  >
    {darkMode.value === "dark" ? <MdOutlineLightMode fontSize={20} /> :
      <MdOutlineDarkMode fontSize={20} />}

  </IconButton>
  const ListItemData = <MenuItem onClick={handleToggle}>
    <ListItemIcon>
      {darkMode.value === "dark" ? <MdOutlineLightMode fontSize={20} /> :
        <MdOutlineDarkMode fontSize={20} />}
    </ListItemIcon>
    {darkMode.value === "dark" ? <Typography>{t("lightMode")}</Typography> : <Typography>{t("darkMode")}</Typography>}
  </MenuItem>

  return listItem ? ListItemData : IconButtonData
};

export default DarkModeIcon;
