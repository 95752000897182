import React, { Fragment, useEffect } from "react";
// import "./ShipmentPrint.css";
import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { withRouter } from "react-router";
import clsx from "clsx";

import { urlParameters } from "../../HOC/CustomFunctions/urlParameters";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const PREFIX = "PRODUCT_STICKY_PRINT";

export const classes = {
  printSticky: `${PREFIX}-printSticky`,
  stickyCode: `${PREFIX}-stickyCode`,
  stickyRecipientName: `${PREFIX}-stickyRecipientName`,
  stickyDestination: `${PREFIX}-stickyDestination`,
  stickyRecipientAddress: `${PREFIX}-stickyRecipientAddress`,
  centerContent: `${PREFIX}-centerContent`,
  barcode: `${PREFIX}-barcode`,
};
export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.printSticky}`]: {
     
    pageBreakInside: "avoid",
    overflow: "hidden",
    fontSize: 11,
    maxWidth: "5cm",
    maxHeight: "2.5cm",
    color: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },

  [`& .${classes.stickyCode}`]: {
    height: "33px",
    overflow: "hidden",
    fontSize: "30px !important",
  },

  [`& .${classes.stickyRecipientName}`]: {
    height: "calc(100% * (0.75/4))",
    overflow: "hidden",
    whiteSpace: "pre",
  },

  [`& .${classes.stickyDestination}`]: {
    height: "calc(100% * (0.75/4))",
    overflow: "hidden",
    whiteSpace: "pre",
  },

  [`& .${classes.stickyRecipientAddress}`]: {
    height: "calc(100% * (1.5/4))",
    overflow: "hidden",
  },
  [`& .${classes.centerContent}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 48,
  },
}));

const listShipmentsFields = [
  {
    data: [
      "id",
      "name",
      "code",
    ],
  },
];

const queryBuilder = (ids) => {
  const queryNumber = Math.ceil(ids.length / 100);
  const queryArray = [];
  for (let index = 0; index < queryNumber; index++) {
    const id = ids.slice(100 * index, 100 * (index + 1));
    const initialQuery = {
      operation: `list${index}:listProducts(first:100,input:{id:[${id}]})`,
      fields: listShipmentsFields,
      variables: {},
    };
    queryArray.push(initialQuery);
  }
  return queryArray;
};

const ShipmentStickyPrint2X5 = (props) => {
  const id = props.match.params.id.split(",").map(Number);
  const urlQuery = urlParameters(window.location.search);
  const size = props.match.params.size;

  const { t, i18n } = useTranslation();

  const queryBody = queryBuilder(id);

  const SHIPMENT_QUERY = gqlb.query(queryBody);

  const token = urlQuery["token"];
  const lang = urlQuery["lang"];

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
    return () => { };
  }, [i18n, lang]);

  const { data, loading } = useQuery(
    gql`
      ${SHIPMENT_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !id,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        input: {
          id,
        },
      },
      ...(token && {
        context: {
          headers: {
            authorization: `Bearer ${token}`,
            ...(lang && { "Content-Language": lang }),
          },
        },
      }),
      onCompleted: () => {
        document.fonts.onloadingdone = function (fontFaceSetEvent) {
          window.print();
          document.fonts.onloadingdone = null;
        };
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  let parsedData = [];
  if (data?.list0) {
    let concatData = [];
    for (const key in data) {
      if (key.startsWith("list")) {
        const listData = data[key].data;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }
  if (data?.listCode) {
    let concatData = [];
    for (const key in data) {
      if (key.startsWith("list")) {
        const listData = data[key].barcodes;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }
  const validData = parsedData.length > 0;

  let shipmentsArray = [];

  if (validData) {
    parsedData.forEach((shipment, i) => {
      let smallShipment = [];
      for (let i = 0; i < parseInt(size); i++) {
        smallShipment.push(shipment);
      }
      shipmentsArray.push(...smallShipment);
    });
  }

  return (
    <Fragment>
      <link
        rel="stylesheet"
        type="text/css"
        href={process.env.PUBLIC_URL + "/product-print-sticky.css"}
      />
      {loading || (!loading && !validData) ? (
        <>
          <EmptyTableMessage
            loading={loading}
            message={t("noShipmentWithCode")}
          />

          {/* <FullScreenLoading minHeight="25%" /> */}
        </>
      ) : (
        shipmentsArray.map((product, index) => (
          <Root key={index}>
            <div
              className={clsx({
                [classes.printSticky]: !loading && validData,
              })}
              style={{ height: "100%", lineHeight: "1.4" }}
            >
              <div
                className={clsx(
                  classes.barcode,
                  classes.centerContent,
                  classes.stickyCode
                )}
              >
                *{product.code}*
              </div>
              <Typography className="productName" variant="caption" display={"block"} textAlign={"center"}>
                {product.name}
              </Typography>
            </div>
          </Root>
        ))
      )}
    </Fragment>
  );
};

export default withRouter(ShipmentStickyPrint2X5);
