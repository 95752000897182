import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Checkbox, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { dateFormatLL } from "../../../helpers/dateFunctions";
import CellLink from "../../HOC/CustomComponents/CellLink";
import { PaymentAmountTableCell } from "./paymentAmountTableCell";

const PREFIX = "PickupListDetails";

const classes = {
  pickupTable_checkbox: `${PREFIX}-pickupTable_checkbox`,
};

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  [`& .${classes.pickupTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    left: 0,
  },
}));

export const paymentPickupsCells = (pickupDetails) => {
  const cellsByType = (
    <>
      <FixedTableCell>{dateFormatLL(pickupDetails?.date)}</FixedTableCell>
      <CellLink pathname={`/admin/customers/${pickupDetails.customer.id}`}>
        {pickupDetails.customer.name}
      </CellLink>
      <FixedTableCell>{pickupDetails?.notes}</FixedTableCell>
      {/* <FixedTableCell>{pickupDetails?.deliveryCommission}</FixedTableCell> */}
      <PaymentAmountTableCell pickupDetails={pickupDetails} />
    </>
  )
  return cellsByType;
};
export function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  const { t } = useTranslation();

  const headCells = [
    t("requestCode"),
    t("theDate"),
    t("customer"),
    t("notes"),
    t("deservedAmount"),
  ];
  return (
    <StyledTableHead>
      <TableRow>
        <FixedTableCell
          className={classes.pickupTable_checkbox}
          padding="checkbox"
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </FixedTableCell>
        {headCells.map((headCell, index) => (
          <FixedTableCell key={index}>{headCell}</FixedTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};
