import {
    Button,
    Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {  Search } from "@mui/icons-material";
import clsx from "clsx";

import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { windowUrl } from "../Component/HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../Component/HOC/CustomFunctions/urlParameters";
import { CustomAutocomplete } from "../Component/HOC/MUI/CustomAutocomplete";
import MUItextField from "../Component/HOC/MUI/MUItextField";
import {
    LIST_CUSTOMERS_DROPDOWN,
} from "../GlobalsQuery/ListDropdown/ListDropdown";
import MUIDateRangeCustom from "../Component/HOC/MUI/MUIDateRangeCustom";

const PREFIX = "shipmentListFilters";

const classes = {
    button: `${PREFIX}-button`,
    btnMargin: `${PREFIX}-btnMargin`,
    searchForm: `${PREFIX}-searchForm`,
    searchField: `${PREFIX}-searchField`,
    overlay: `${PREFIX}-overlay`,
    searchPadding: `${PREFIX}-searchPadding`,
};

const Root = styled(Grid)(({ theme }) => ({
    [`& .${classes.button}`]: {
        position: "sticky",
        bottom: 0,
        zIndex: 3,
        backgroundColor: theme.palette.background.paper,
        marginTop: 10,
        padding: 7,
    },

    [`& .${classes.btnMargin}`]: {
        width: "100%",
    },

    [`&.${classes.searchForm}`]: {
        overflowY: "scroll",
        width: "100%",
        margin: 0,
        height: "100vh",
    },

    [`& .${classes.searchField}`]: {
        padding: theme.spacing(1, 1, 0, 1),
        position: "relative",
        overflow: "hidden",
    },

    [`& .${classes.overlay}`]: {
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: theme.palette.background.paper,
        opacity: 0.8,
        zIndex: 2,
    },

    //////////////////////Drawer///////////////////
    [`& .${classes.searchPadding}`]: {
        padding: theme.spacing(1),
    },
}));

const PickupFiltersForm = ({
    loading,
    resetPage,
    onSubmitFunc,
    queryVariables,
    rowsPerPage,
    preventPushUrl,
    pathname,
    customerType,
    noData
}) => {
    const { handleSubmit, register, errors, watch, control, setValue } =
        useForm();
    const history = useHistory();
    const urlQuery = urlParameters(window.location.search);

    const initFromDate = urlQuery["createdFrom"] ? urlQuery["createdFrom"] : null;
    const initToDate = urlQuery["createdTo"] ? urlQuery["createdTo"] : null;
    const [dateRange, setDateRange] = useState([initFromDate, initToDate]);


    const validUrlParameters = Object.keys(urlQuery).length !== 0;

    const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
    const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
    const initSearch = () => {
        let searchParameters = {
            refetch: true,
            ...(dateRange[0] && { fromDate }),
            ...(dateRange[1] && { toDate }),
        };
        if (validUrlParameters) {
            if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"]?.toString();
            const searchParams = { ...urlQuery };
            delete searchParams["rowsPerPage"];
            delete searchParams["page"];

            searchParameters = searchParams;
        }
        return searchParameters;
    };
    const [search, setSearch] = useState(initSearch());

    const pushUrlSearch = (param) => {
        if (preventPushUrl) return;
        if (urlQuery["idleSearch"] || urlQuery["idleSearch"] === '0') {
            param.idleSearch = urlQuery["idleSearch"]
        }
        const queryParams = [];
        for (const i in param) {
            encodeURIComponent(param[i]) &&
                queryParams.push(
                    encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
                );
        }
        const queryString = queryParams.join("&");

        const url = history.createHref({
            pathname: `/admin/${pathname}`,
            search: "?" + queryString,
        });
        windowUrl(url);
    };

    useEffect(() => {
        // dateValue && dateValue(dateValueWithCode());
        const inputSearch = { ...search };
        queryVariables(inputSearch);
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);


    const onSubmit = (data) => {
        onSubmitFunc && onSubmitFunc(data);

        // moment.locale("en");
        let handledData = {
            ...(dateRange[0] && {
                fromDate,
            }),
            ...(dateRange[1] && {
                toDate,
            }),
            code: data?.code?.toString(),
            customerId: data.customerId,
        };

        for (const key in handledData) {
            if ([undefined, "", null].includes(handledData[key])) {
                delete handledData[key];
            }
        }

        resetPage();
        const newUrlParameters = { ...handledData };
        pushUrlSearch({
            ...newUrlParameters,
            rowsPerPage,
            page: 0,
        });

        if (handledData.code) {
            handledData = {
                code: data.code.toString(),
                // page: 0,
            };
        }

        setSearch((prev) => ({
            // ...prev,
            ...handledData,
            refetch: !prev.refetch,
        }));
    };

    const [autocompleteValues, setAutocompleteValues] = useState({
        customer: null,
    });

    useEffect(() => {
        urlQuery["code"] && setValue("code", urlQuery["code"].toString());
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { t } = useTranslation();
    const parseData = (data) => {
        return data;
    };

    const handelAutocompleteDefaultValue = (data, id, fieldName) => {
        const defaultValue = data[Object.keys(data)[0]].find(
            (i) => i.id === parseInt(id)
        );
        defaultValue &&
            setAutocompleteValues((prev) => ({
                ...prev,
                [fieldName]: defaultValue,
            }));
    };

    const resetDate = () => {
        setDateRange(Array.isArray(dateRange) ? [null, null] : "");
    };

    return (
        <Root
            container
            item
            justifyContent="center"
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
            className={classes.searchForm}
            alignContent="flex-start"
        >
            <Grid
                container
                item
                sm={12}
                justifyContent="flex-start"
                spacing={1}
                className={classes.searchField}
            >
                <Grid container item sm={12} alignItems="flex-start">
                    <MUItextField
                        label={t("code")}
                        name={"code"}
                        inputProps={{
                            dir: "ltr",
                        }}
                        formType={"requireFalse"}
                        register={register}
                        errors={errors}
                        watch={watch("code")}
                    />
                </Grid>
                <Grid container item sm={12} alignItems="flex-start">
                    <CustomAutocomplete
                        name={"customerId"}
                        label={
                            ["", "MERCHANT", undefined].includes(
                                customerType ?? watch("customerTypeCode")
                            )
                                ? t("customer")
                                : t("individual")
                        }
                        skip={noData}
                        control={control}
                        errors={errors}
                        parseData={(data) => parseData(data)}
                        onCompleted={(data) =>
                            handelAutocompleteDefaultValue(
                                data,
                                urlQuery["customerId"],
                                "customer"
                            )
                        }
                        query={LIST_CUSTOMERS_DROPDOWN.query}
                        variables={{
                            input: {
                                active: true,
                                typeCode: ["", "MERCHANT", undefined].includes(
                                    customerType ?? watch("customerTypeCode")
                                )
                                    ? "MERCHANT"
                                    : "INDIVIDUAL",
                            },
                        }}
                        defaultValue={autocompleteValues.customer}
                    />
                </Grid>
                <Grid container item sm={12} alignItems="flex-start">
                    <MUIDateRangeCustom
                        value={dateRange}
                        onChangeValue={(value) => setDateRange(value)}
                        resetDate={resetDate}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                item
                sm={12}
                className={clsx(classes.searchPadding, classes.button)}
                alignItems="center"
                justifyContent="flex-end"
            >
                <Button
                    className={clsx(classes.btnMargin)}
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    startIcon={<Search />}
                    disabled={loading}
                >
                    {t("search")}
                </Button>
            </Grid>
        </Root>
    );
};

// export default FiltersForm;

export default memo(
    PickupFiltersForm,
    (prevProps, nextProps) =>
        prevProps.noData === nextProps.noData &&
        prevProps.loading === nextProps.loading &&
        prevProps.rowsPerPage === nextProps.rowsPerPage
);
