import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { useQuery, gql } from "@apollo/client";
import { TableBody, TableRow, Button } from "@mui/material";
import { FilterList, Print, Search } from "@mui/icons-material";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_DELIVERY_AGENTS_DUE_INQUIRY } from "./Graphql";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import MUIselect from "../HOC/MUI/MUIselect";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { useHistory } from "react-router";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useForm } from "react-hook-form";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_ROUTES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import useWidth from "../../Hooks/useWidth";

import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import Grid from "@mui/material/Unstable_Grid2";
import config from "../../config.json";

import formatMoney from "../../helpers/numbersDot";
import ExportDeliveryAgentList from "./ExportDeliveryAgentList";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
const PREFIX = "ListDeliveryAgentDueInquiry";

const classes = {
  span: `${PREFIX}-span`,
  iconColor: `${PREFIX}-iconColor`,
  headerTable: `${PREFIX}-headerTable`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
  stars: `${PREFIX}-stars`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.span}`]: {
    display: "inline",
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%",
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column",
  },

  [`& .${classes.stars}`]: {
    marginRight: theme.spacing(0.5),
  },
}));

const ListDeliveryAgentDueInquiry = (props) => {
  const [refetch, setrefetch] = useState(true);
  const { t } = useTranslation();
  const urlQuery = urlParameters(window.location.search);

  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    route: null,
  });
  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const [page, setPage] = useState(urlQuery["page"] ?? 0);

  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    delete urlQuery["page"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);
    urlQuery["autoPayCommission"] !== undefined &&
      setValue("autoPayCommission", urlQuery["autoPayCommission"]);
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/delivery-agents/due-inquiry",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";
  const [noData, setNoData] = useState(true);
  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setrefetch(false) : setrefetch(true);
    let handledData = {
      active: data.active,
      autoPayCommission: data.autoPayCommission,
      routeId: data.routeId,
      branchId: data.branchId,
      name: data.name,
      code: data.code,
      page: 0,
    };
    data["active"] === "" && delete handledData["active"];
    data["autoPayCommission"] === "" && delete handledData["autoPayCommission"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
    setPage(0)
  };

  const parseData = (data) => {
    return data;
  };
  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });
    setPage(newPage);
    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setPage(0);
    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const [openExport, setOpenExport] = useState(false);

  const openExportDialog = () => {
    setOpenExport(true);
  };
  const { data, loading } = useQuery(
    gql`
      ${LIST_DELIVERY_AGENTS_DUE_INQUIRY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          withDue: null,
          active: search.active,
          autoPayCommission: search.autoPayCommission,
          ...(search.code && { code: search.code }),
          ...(search.name && { name: search.name }),
          ...(search.routeId && { routeId: search.routeId }),
          ...(search.branchId && {
            branchId: { value: search.branchId, includeNull: false },
          }),
        },
      },
      onCompleted: (data) => {
        setNoData(false);
      },
    }
  );

  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const onPrint = () => {
    const params = searchParams(urlQuery);
    const domain = `${window.location.origin}`;
    window.open(`${domain}/delivery-agents-due-inquiry-list/print?${params}`);
  };

  const exportFilters = {
    withDue: null,
    active: search.active,
    autoPayCommission: search.autoPayCommission,
    ...(search.code && { code: search.code }),
    ...(search.name && { name: search.name }),
    ...(search.routeId && { routeId: search.routeId }),
    ...(search.branchId && {
      branchId: { value: search.branchId, includeNull: false },
    }),
  };

  const deliveryAgentList =
    data?.listDeliveryAgents?.data !== null
      ? data?.listDeliveryAgents?.data
      : null;

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "printList",
      title: "printList",
      action: onPrint,
      icon: Print,
      disabled: Boolean(deliveryAgentList?.length === 0),
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: Boolean(deliveryAgentList?.length === 0),
    },
  ];

  return (
    <Root>
      <ExportDeliveryAgentList
        filters={exportFilters}
        openExport={openExport}
        setOpenExport={setOpenExport}
        dueInquiry={true}
        name={"export-deliveryAgentDueInquiry"}
      />
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={deliveryAgentList?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"active"}
                  label={t("active")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notActive"), value: false },
                    { key: t("active"), value: true },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"autoPayCommission"}
                  label={t("autoPayCommission")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("no"), value: false },
                    { key: t("yes"), value: true },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  label={t("branch")}
                  skip={noData}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"routeId"}
                  label={t("route")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["routeId"],
                      "route"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_ROUTES_DROPDOWN.query}
                  defaultValue={autocompleteValues.route}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={[
          {
            name: "name",
          },
          {
            name: "code",
          },
          {
            name: "branch",
          },
          {
            name: "collectAmount",
          },
          {
            name: "dueAmount",
          },
          {
            name: "net",
          },
        ]}
        tableBody={
          <TableBody>
            {deliveryAgentList &&
              deliveryAgentList?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <CellLink pathname={`/admin/delivery-agents/${row?.id}`}>
                      {row?.name}
                    </CellLink>
                    <FixedTableCell>{row?.code}</FixedTableCell>

                    <CellLink pathname={`/admin/branches/${row?.branch?.id}`}>
                      {row?.branch?.name}
                    </CellLink>

                    <FixedTableCell>
                      {formatMoney(row?.collectionDueAmount)}
                    </FixedTableCell>
                    <FixedTableCell>
                      {formatMoney(row?.commissionDueAmount)}
                    </FixedTableCell>
                    <FixedTableCell>
                      {formatMoney(
                        row?.collectionDueAmount - row?.commissionDueAmount
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listDeliveryAgents?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listDeliveryAgents ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default ListDeliveryAgentDueInquiry;
