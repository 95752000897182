/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { TableBody, TableHead, TableRow, Button, Icon } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";

import { useForm } from "react-hook-form";
import { FilterList, Add, Search, Print } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import MUIselect from "../HOC/MUI/MUIselect";
import { LIST_PAYMENT_QUERY } from "./PaymentListQuary";
import moment from "moment";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Can } from "../HOC/CustomComponents/Secured";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import CellLink from "../HOC/CustomComponents/CellLink";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_ZONES_DROPDOWN,
  LIST_SAFES_DROPDOWN,
  LIST_BRANCHES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import useWidth from "../../Hooks/useWidth";
import { dateFormatLL } from "../../helpers/dateFunctions";
import ExportPaymentList from "./ExportPaymentList";
import { GetPaymentPermissionSlug } from "../../helpers/getPermissionSlug";
import formatMoney from "../../helpers/numbersDot";
import { Globals } from "../HOC/Classes/Globals";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
const PREFIX = "PaymentList";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%",
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column",
  },
}));

///////////////////////////////////////////////////

//////////////////////////////////////////////////

function EnhancedTableHead(props) {
  const { ALLOWED_CONCIERGE, ALLOWED_SHIPMENT, ALLOWED_PICKUP, DLVBY } = props;
  const isUser = Boolean(Globals.user.account);

  //*********Table Function*********
  const { t } = useTranslation();
  const headCells = [
    { id: "1", numeric: true, disablePadding: false, label: t("recordCode") },
    { id: "2", numeric: true, disablePadding: false, label: t("paymentDate") },
    {
      id: "3",
      numeric: true,
      disablePadding: false,
      label: t("shippingAgent"),
    },
    { id: "4", numeric: true, disablePadding: false, label: t("safe") },
    { id: "6", numeric: true, disablePadding: false, label: t("value") },
    { id: "7", numeric: true, disablePadding: false, label: t("approved") },
  ];
  if (!DLVBY) {
    const referenceBranch = {
      id: "5",
      numeric: true,
      disablePadding: false,
      label: t("returningDueFees"),
    };
    headCells.splice(4, 0, referenceBranch);
    if (!isUser) {
      const paymentMethod = {
        id: "18",
        numeric: true,
        disablePadding: false,
        label: t("paymentMethod"),
      };

      const bank = {
        id: "19",
        numeric: true,
        disablePadding: false,
        label: t("bank"),
      };

      const accountNumber = {
        id: "20",
        numeric: true,
        disablePadding: false,
        label: t("accountNumber"),
      };

      const glApproved = {
        id: "8",
        numeric: true,
        disablePadding: false,
        label: t("glApproved"),
      };

      const createdBy = {
        id: "11",
        numeric: true,
        disablePadding: false,
        label: t("createdBy"),
      };

      const updatedBy = {
        id: "12",
        numeric: true,
        disablePadding: false,
        label: t("updatedBy"),
      };

      headCells.splice(6, 0, paymentMethod);
      headCells.splice(7, 0, bank);
      headCells.splice(8, 0, accountNumber);
      headCells.splice(10, 0, glApproved);
      headCells.splice(14, 0, createdBy);
      headCells.splice(15, 0, updatedBy);
    }
  }
  if (DLVBY) {
    const shipmentsNumber = {
      id: "9",
      numeric: true,
      disablePadding: false,
      label: t("shipments"),
    };
    // headCells.splice(4, 0, shipmentsNumber);
    const conciergeRequestsNumber = {
      id: "13",
      numeric: true,
      disablePadding: false,
      label: t("conciergeRequestsNumber"),
    };
    const referenceBranch = {
      id: "10",
      numeric: true,
      disablePadding: false,
      label: t("pickups"),
    };
    if (ALLOWED_CONCIERGE) {
      headCells.splice(4, 0, conciergeRequestsNumber);
    }
    if (ALLOWED_SHIPMENT) {
      if (ALLOWED_CONCIERGE) {
        headCells.splice(5, 0, shipmentsNumber);
      } else {
        headCells.splice(4, 0, shipmentsNumber);
      }
    }
    if (ALLOWED_PICKUP) {
      if (ALLOWED_SHIPMENT) {
        if (ALLOWED_CONCIERGE) {
          headCells.splice(6, 0, referenceBranch);
        } else {
          headCells.splice(5, 0, referenceBranch);
        }
      } else {
        headCells.splice(4, 0, referenceBranch);
      }
    }

    if (!isUser) {
      const shipmentsNumber = {
        id: "8",
        numeric: true,
        disablePadding: false,
        label: t("glApproved"),
      };
      headCells.splice(9, 0, shipmentsNumber);

      const createdBy = {
        id: "11",
        numeric: true,
        disablePadding: false,
        label: t("createdBy"),
      };
      headCells.splice(10, 0, createdBy);
      const updatedBy = {
        id: "12",
        numeric: true,
        disablePadding: false,
        label: t("updatedBy"),
      };
      headCells.splice(11, 0, updatedBy);
    }
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (!DLVBY && headCell["id"] === "3") {
            headCell["label"] = t("customer");
          } else if (DLVBY && headCell["id"] === "3") {
            headCell["label"] = t("shippingAgent");
          }
          return (
            <FixedTableCell
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </FixedTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

/////////////////DIALOG///////////////

const initDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const PaymentList = (props) => {
  const isUser = Boolean(Globals.user.account);
  const [refetch, setRefetch] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const screenWidth = useWidth();
  const listType = props.match.params.type;
  const history = useHistory();
  const { t } = useTranslation();
  const [noData, setNoData] = useState(true);
  const ALLOWED_CONCIERGE =
    Globals.settings.allowedCustomerTypes.includes("CONCIERGE");
  const ALLOWED_SHIPMENT =
    Globals.settings.allowedCustomerTypes.includes("INDIVIDUAL") ||
    Globals.settings.allowedCustomerTypes.includes("MERCHANT");
  const ALLOWED_PICKUP =
    Globals.settings.allowedCustomerTypes.includes("INDIVIDUAL") ||
    Globals.settings.allowedCustomerTypes.includes("MERCHANT");
  const DLVBY = listType === "dlvby";
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const validUrlParameters =
    Object.keys(urlQuery).length !== 0 &&
    !Object.keys(urlQuery).includes("fromDate", "toDate");

  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : validUrlParameters
      ? null
      : initDate;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : validUrlParameters
      ? null
      : initDate;
  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);
  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [queryVar, setQuaryVar] = useState(null);

  const [state, setState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const [autocompleteValues, setAutocompleteValues] = useState({
    deliveryAgent: null,
    customer: null,
    toZone: null,
    toSubzone: null,
    fromZone: null,
    fromSubzone: null,
  });

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const resetDate = () => {
    setDateRange([null, null]);
  };
  const parseData = (data) => {
    return data;
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm();

  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const pushUrlSearch = (param) => {
    const params = searchParams(param);

    const url = history.createHref({
      pathname: `/admin/payments/${listType}`,
      search: "?" + params,
    });
    windowUrl(url);
  };

  useEffect(() => {
    const { first, page, ...input } = urlQuery;
    if (input["code"]) input["code"] = input["code"].toString();
    setQuaryVar((prev) => ({
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
      ...prev,
      ...input,
      ...{ typeCode: listType.toUpperCase() },
    }));
    urlQuery["code"] && setValue("code", urlQuery["code"].toString());
    urlQuery["approved"] !== undefined &&
      setValue("approved", urlQuery["approved"]);
    urlQuery["glApproved"] !== undefined &&
      setValue("glApproved", urlQuery["glApproved"]);
    urlQuery["first"] && setRowsPerPage(parseInt(urlQuery["first"]));
    urlQuery["page"] && setPage(parseInt(urlQuery["page"] - 1));
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pushUrlSearch({ ...urlQuery, page: newPage + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    pushUrlSearch({ ...urlQuery, page: 1, first: +event.target.value });
  };
  const onSubmit = (data) => {
    setRefetch((prev) => !prev);
    data["fromDate"] = dateRange[0] && fromDate;
    data["toDate"] = dateRange[1] && toDate;
    filterAnchor === "bottom" &&
      setState((prev) => ({ ...prev, [filterAnchor]: false }));
    for (const key in data) {
      if (data[key] === "" || data[key] === null) {
        delete data[key];
      }
    }
    pushUrlSearch({
      ...data,
      first: rowsPerPage,
      page: 1,
    });
    setQuaryVar({
      ...data,
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      ...{
        typeCode: listType.toUpperCase(),
      },
    });
    setPage(0);
  };

  // List Query
  const listQuery = LIST_PAYMENT_QUERY(
    ALLOWED_CONCIERGE,
    ALLOWED_SHIPMENT,
    ALLOWED_PICKUP
  );
  const { data, loading } = useQuery(
    gql`
      ${listQuery.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !queryVar,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          ...queryVar,
        },
      },
      onCompleted: (data) => {
        setNoData(false);
      },
    }
  );
  const paymentData =
    data?.listPayments?.data !== null ? data?.listPayments?.data : null;
  const sumPayments = data?.sumPayments;

  const onPrint = (param) => {
    const params = searchParams(queryVar);
    const domain = `${window.location.origin}`;
    const user = `${isUser ? "/account" : ""}`;
    window.open(`${domain}${param}${user}?${params}`);
  };
  const emptyListPayments = data?.listPayments?.data?.length === 0;

  const addURL = () => {
    pushUrl(props, `/admin/payments/create/${listType}`);
  };

  const [openExport, setOpenExport] = useState(false);
  const openExportDialog = () => {
    setOpenExport(true);
  };
  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !state[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: addURL,
      icon: Add,
      permission: GetPaymentPermissionSlug(listType, "create"),
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: !data || Boolean(data?.listPayments?.data?.length === 0),
    },
    {
      id: "printList",
      title: "printPaymentsList",
      action: () => onPrint("/payments/print"),
      icon: Print,
      disabled: emptyListPayments,
    },
    {
      id: "print",
      title: "printPaymentsDetails",
      action: () =>
        onPrint(`/report/print/payment/n/${listType.toUpperCase()}`),
      icon: Print,
      disabled: emptyListPayments,
    },
  ];

  const customerField = (type) => {
    if (type === "custm" && !isUser) {
      return (
        <Grid item sm={12} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"customerId"}
            label={t("customer")}
            query={LIST_CUSTOMERS_DROPDOWN.query}
            skip={listType !== "custm" || !queryVar || noData}
            onCompleted={(data) =>
              handelAutocompleteDefaultValue(
                data,
                urlQuery["customerId"],
                "customer"
              )
            }
            parseData={(data) => parseData(data)}
            variables={{
              input: {
                active: true,
                typeCode: ["MERCHANT"],
              },
            }}
            defaultValue={autocompleteValues.customer}
          />
        </Grid>
      );
    } else if (type === "dlvby") {
      return (
        <Grid item sm={12} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"deliveryAgentId"}
            label={t("shippingAgent")}
            skip={listType !== "dlvby" || !queryVar || noData}
            onCompleted={(data) =>
              handelAutocompleteDefaultValue(
                data,
                urlQuery["deliveryAgentId"],
                "deliveryAgent"
              )
            }
            parseData={(data) => parseData(data)}
            query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
            variables={{
              input: {
                active: true,
              },
            }}
            defaultValue={autocompleteValues.deliveryAgent}
          />
        </Grid>
      );
    }
  };

  return (
    <Root>
      {props.children}
      {openExport && <ExportPaymentList
        filters={queryVar}
        openExport={openExport}
        setOpenExport={setOpenExport}
      />}
      <ListWrapper
        drawerState={state[filterAnchor]}
        icons={icons}
        path={props.match.path}
        type={listType}
        empty={data?.listPayments.data?.length === 0}
        loading={!queryVar || loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} xs={12}>
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                  resetDate={resetDate}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              {!isUser && (
                <Grid item sm={12} xs={12}>
                  <MUIselect
                    control={control}
                    errors={errors}
                    name={"approved"}
                    label={t("status")}
                    data={[
                      { key: t("approved"), value: true },
                      { key: t("notApproved"), value: false },
                    ]}
                    defaultValue={urlQuery?.approved}
                  />
                </Grid>
              )}
              {!isUser && (
                <Grid item sm={12}>
                  <CustomAutocomplete
                    name={"branchId"}
                    label={t("currentBranch")}
                    control={control}
                    errors={errors}
                    skip={!queryVar || noData}
                    parseData={(data) => parseData(data)}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["branchId"],
                        "branch"
                      )
                    }
                    query={LIST_BRANCHES_DROPDOWN.query}
                    defaultValue={autocompleteValues.branch}
                  />
                </Grid>
              )}
              {!isUser && (
                <Grid item sm={12} xs={12}>
                  <MUIselect
                    control={control}
                    errors={errors}
                    name={"glApproved"}
                    label={t("glApprove")}
                    data={[
                      { key: t("glApproved"), value: true },
                      { key: t("notGlApproved"), value: false },
                    ]}
                    defaultValue={urlQuery?.approved}
                  />
                </Grid>
              )}
              {customerField(listType)}
              {!isUser && (
                <Grid item sm={12} xs={12}>
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    skip={!queryVar || noData}
                    name={"safeId"}
                    label={t("safe")}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["safeId"],
                        "safe"
                      )
                    }
                    parseData={(data) => parseData(data)}
                    query={LIST_SAFES_DROPDOWN.query}
                    defaultValue={autocompleteValues.safe}
                  />
                </Grid>
              )}
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={!queryVar || noData}
                  name={"fromZoneId"}
                  label={t("fromZone")}
                  onChangeValue={(e) => {
                    setValue("fromSubzoneId", "");
                    delete urlQuery["fromSubzoneId"];

                    pushUrlSearch({ ...urlQuery });
                  }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["fromZoneId"],
                      "fromZone"
                    )
                  }
                  variables={{ input: { parentId: null } }}
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.fromZone}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"fromSubzoneId"}
                  label={t("fromSubzone")}
                  skip={!watch("fromZoneId")}
                  disabled={!watch("fromZoneId")}
                  variables={{ input: { parentId: watch("fromZoneId") } }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["fromSubzoneId"],
                      "fromSubzone"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.fromSubzone}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={!queryVar || noData}
                  name={"toZoneId"}
                  label={t("toZone")}
                  onChangeValue={(e) => {
                    delete urlQuery["toSubzoneId"];
                    setValue("toSubzoneId", "");

                    pushUrlSearch({ ...urlQuery });
                  }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["toZoneId"],
                      "toZone"
                    )
                  }
                  variables={{ input: { parentId: null } }}
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.toZone}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"toSubzoneId"}
                  label={t("toSubzone")}
                  skip={!watch("toZoneId")}
                  disabled={!watch("toZoneId")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["toSubzoneId"],
                      "toSubzone"
                    )
                  }
                  variables={{ input: { parentId: watch("toZoneId") } }}
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.toSubzone}
                />
              </Grid>
              <Can permission={listType === "custm" && !isUser} showException>
                <Grid item sm={12} xs={12}>
                  <LookupDropdown
                    control={control}
                    errors={errors}
                    skip={!queryVar || noData}
                    name={"paymentMethodCode"}
                    label={t("paymentType")}
                    variables={{
                      input: { code: "SHP_PAYMENT_METHODS" },
                    }}
                    onCompleted={(data) =>
                      lookupsComplete(
                        data,
                        urlQuery["paymentMethodCode"],
                        "paymentMethodCode"
                      )
                    }
                  />
                </Grid>
              </Can>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          <EnhancedTableHead
            ALLOWED_CONCIERGE={ALLOWED_CONCIERGE}
            ALLOWED_SHIPMENT={ALLOWED_SHIPMENT}
            ALLOWED_PICKUP={ALLOWED_PICKUP}
            DLVBY={DLVBY}
          />
        }
        tableBody={
          <TableBody>
            {paymentData &&
              paymentData?.map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                // onClick={(e) => {
                //   e.stopPropagation();
                //   pushUrl(props, `/admin/payments/${row?.id}/edit`);
                // }}
                >
                  <CellLink
                    pathname={`/admin/payments/${row?.id}`}
                    component="th"
                    scope="row"
                  >
                    {row.code}
                  </CellLink>
                  <FixedTableCell>{dateFormatLL(row?.date)}</FixedTableCell>
                  {row?.deliveryAgent || row?.customer ? (
                    <CellLink
                      pathname={
                        DLVBY
                          ? `/admin/delivery-agents/${row?.deliveryAgent?.id}`
                          : `/admin/customers/${row?.customer?.id}`
                      }
                    >
                      {DLVBY ? row?.deliveryAgent?.name : row?.customer?.name}
                    </CellLink>
                  ) : (
                    <FixedTableCell> </FixedTableCell>
                  )}

                  {row?.safe ? (
                    <CellLink pathname={`/admin/safes/${row?.safe?.id}`}>
                      {row?.safe?.name}
                    </CellLink>
                  ) : (
                    <FixedTableCell> </FixedTableCell>
                  )}
                  {DLVBY && ALLOWED_CONCIERGE && (
                    <FixedTableCell>
                      {row?.entriesConcierge?.paginatorInfo?.total}
                    </FixedTableCell>
                  )}
                  {DLVBY && ALLOWED_SHIPMENT && (
                    <FixedTableCell>
                      {row?.entriesShipment?.paginatorInfo?.total}
                    </FixedTableCell>
                  )}
                  {!DLVBY && (
                    <FixedTableCell>
                      {formatMoney(row?.sumEntries?.dueFees)}
                    </FixedTableCell>
                  )}
                  {DLVBY && (
                    <FixedTableCell>
                      {row?.entriesPickup?.paginatorInfo?.total}
                    </FixedTableCell>
                  )}
                  <FixedTableCell>
                    {formatMoney(row?.sumEntries?.paymentAmount)}
                  </FixedTableCell>

                  {!DLVBY && !isUser && (
                    <FixedTableCell>
                      {row?.customer?.paymentMethod?.name}
                    </FixedTableCell>
                  )}
                  {!DLVBY && !isUser && (
                    <FixedTableCell>{row?.customer?.bank?.name}</FixedTableCell>
                  )}
                  {!DLVBY && !isUser && (
                    <FixedTableCell>
                      {row?.customer?.paymentMethod?.code === "VCSH"
                        ? row?.customer?.vodafoneCash
                        : row?.customer?.accountNumber}
                    </FixedTableCell>
                  )}

                  <FixedTableCell>
                    {row?.approved ? (
                      <Icon className={classes.iconColor}>
                        check_circle_outline
                      </Icon>
                    ) : (
                      <Icon color="error">highlight_off</Icon>
                    )}
                  </FixedTableCell>
                  {!isUser && (
                    <FixedTableCell>
                      {row?.glApproved ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  )}
                  {!isUser && (
                    <CellLink
                      align="left"
                      pathname={`/admin/users/${row?.createdBy?.id}`}
                    >
                      {row?.createdBy?.username}
                    </CellLink>
                  )}
                  {!isUser && (
                    <CellLink
                      align="left"
                      pathname={`/admin/users/${row?.updatedBy?.id}`}
                    >
                      {row?.updatedBy?.username}
                    </CellLink>
                  )}
                </TableRow>
              ))}
            <TableRow>
              <FixedTableCell colSpan={3} />
              <FixedTableCell variant="head">{t("total")}</FixedTableCell>
              {!DLVBY && (
                <FixedTableCell variant="head">
                  {formatMoney(sumPayments?.dueFees)}
                </FixedTableCell>
              )}
              {DLVBY && ALLOWED_CONCIERGE && (
                <FixedTableCell variant="head">
                  {formatMoney(sumPayments?.conciergeRequestCount)}
                </FixedTableCell>
              )}
              {DLVBY && ALLOWED_SHIPMENT && (
                <FixedTableCell variant="head">
                  {formatMoney(sumPayments?.shipmentCount)}
                </FixedTableCell>
              )}
              {DLVBY && ALLOWED_PICKUP && (
                <FixedTableCell variant="head">
                  {sumPayments?.pickupCount}
                </FixedTableCell>
              )}
              <FixedTableCell variant="head">
                {formatMoney(sumPayments?.paymentAmount)}
              </FixedTableCell>
              <FixedTableCell colSpan={7}> </FixedTableCell>
            </TableRow>
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listPayments?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listPayments ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default PaymentList;
