import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";

import { gql, useQuery } from "@apollo/client";
import { FilterList, Search } from "@mui/icons-material";
import { Box, Button, TableBody, TableRow } from "@mui/material";
// import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_Product_DROPDOWN,
  LIST_WAREHOUSE_MANIFEST_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_PRODUCTS_TRANSACTIONS } from "./Graphql";
import moment from "moment";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import { Can } from "../HOC/CustomComponents/Secured";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import Grid from "@mui/material/Unstable_Grid2";
import config from "../../config.json";
import ExportProductsTransactions from "./ExportProductsTransactions";

const PREFIX = "ProductList";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%",
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column",
  },
}));

const ProductsTransactions = (props) => {
  const { t } = useTranslation();
  const [refetch, setrefetch] = useState(true);
  const [filterLoad, setFilterLoad] = useState(true);
  const urlQuery = urlParameters(window.location.search);

  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const initFromDate = urlQuery["fromDate"] ? urlQuery["fromDate"] : null;
  const initToDate = urlQuery["toDate"] ? urlQuery["toDate"] : null;
  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    zone: null,
    subzone: null,
    customer: null,
    product: null,
    statusCode: [],
    transactionTypeCode: [],
    warehouseManifest: null,
  });
  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      if (urlQuery["statusCode"])
        urlQuery["statusCode"] = urlQuery["statusCode"].split(",");
      if (urlQuery["transactionTypeCode"])
        urlQuery["transactionTypeCode"] =
          urlQuery["transactionTypeCode"].split(",");
      if (urlQuery["quantity"])
        urlQuery["quantity"] = parseInt(urlQuery["quantity"]);
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());
  const [openExport, setOpenExport] = useState(false);
  const openExportDialog = () => {
    setOpenExport(true);
  };
  useEffect(() => {
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["quantity"] &&
      setValue("quantity", parseInt(urlQuery["quantity"]));
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    setAutocompleteValues((prev) => ({
      ...prev,
      ...(urlQuery["statusCode"] && {
        statusCode: urlQuery["statusCode"],
      }),
    }));
    setAutocompleteValues((prev) => ({
      ...prev,
      ...(urlQuery["transactionTypeCode"] && {
        transactionTypeCode: urlQuery["transactionTypeCode"],
      }),
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/products-transactions",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    setFilterLoad(false);
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setrefetch(false) : setrefetch(true);
    let handledData = {
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      ...(data.quantity && {
        quantity: parseInt(data.quantity),
      }),
      customerId: data.customerId,
      branchId: data.branchId,
      warehouseManifestId: data.warehouseManifestId,
      productId: data.productId,
      statusCode: data.statusCode,
      transactionTypeCode: data.transactionTypeCode,
      name: data.name,
      code: data.code,
      page: 0,
    };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const parseData = (data) => {
    return data;
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };
  const codeValidation = Boolean(watch("code"));
  const user = Globals.user;
  const showManifest = user.hasPermission("shipping.warehouse_manifest.view");
  const queryData = {
    ...(search.customerId && { customerId: search.customerId }),
    ...(search.fromDate && { fromDate: search.fromDate }),
    ...(search.toDate && { toDate: search.toDate }),
    // ...(search.code && { code: search.code }),
    // ...(search.name && { name: search.name }),
    ...(search.quantity && { quantity: parseInt(search.quantity) }),
    // ...(search.customerId && { customerId: search.customerId, }),
    ...(search.branchId && { branchId: search.branchId }),
    ...(search.warehouseManifestId && {
      warehouseManifestId: search.warehouseManifestId,
    }),
    ...(search.productId && { productId: search.productId }),
    ...(search.statusCode && { statusCode: search.statusCode }),
    ...(search.transactionTypeCode && {
      transactionTypeCode: search.transactionTypeCode,
    }),
  };
  // const paymentDueAmount = user.hasPermission("shipping.payment.create");
  // const queryBody = LIST_CUSTOMERS(paymentDueAmount).query;
  const { data, loading } = useQuery(
    gql`
      ${LIST_PRODUCTS_TRANSACTIONS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.customerId && { customerId: search.customerId }),
          ...(search.fromDate && { fromDate: search.fromDate }),
          ...(search.toDate && { toDate: search.toDate }),
          ...(search.code && { code: search.code }),
          ...(search.name && { name: search.name }),
          ...(search.quantity && { quantity: parseInt(search.quantity) }),
          ...(search.customerId && { customerId: search.customerId }),
          ...(search.branchId && { branchId: search.branchId }),
          ...(search.warehouseManifestId && {
            warehouseManifestId: search.warehouseManifestId,
          }),
          ...(search.productId && { productId: search.productId }),
          ...(search.statusCode && { statusCode: search.statusCode }),
          ...(search.transactionTypeCode && {
            transactionTypeCode: search.transactionTypeCode,
          }),
        },
      },
      onCompleted: (data) => {},
    }
  );
  const listProductTransactions =
    data?.listProductTransactions?.data !== null
      ? data?.listProductTransactions?.data
      : null;

  const resetDate = () => {
    setDateRange([null, null]);
  };

  const transactionTypeDataHandler = (data) => {
    return (
      data &&
      data.filter(
        (i) =>
          i.code === "PADD" ||
          i.code === "PRTRN" ||
          i.code === "PMBT" ||
          i.code === "PMBR" ||
          i.code === "PRTS" ||
          i.code === "PISSUE"
      )
    );
  };

  const statusDataHandler = (data) => {
    return (
      data &&
      data.filter(
        (i) =>
          i.code === "TRANSACTED" ||
          i.code === "RESERVED" ||
          i.code === "IN_TRANSIT"
      )
    );
  };

  const parseDataWarehouse = (data) => {
    const parsed = data?.filter((i) => (i.name = i.code));
    return parsed;
  };
  if (autocompleteValues.fromManifest) {
    autocompleteValues.fromManifest.name = autocompleteValues.fromManifest.code;
  }

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: !data || Boolean(data.length === 0),
    },
  ];

  return (
    <Root>
      <ExportProductsTransactions
        filters={queryData}
        openExport={openExport}
        setOpenExport={setOpenExport}
      />
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={data?.listProductTransactions.data.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                  disabled={codeValidation}
                  resetDate={resetDate}
                />
              </Grid>
              <Can showException permission={!Boolean(user.account)}>
                <Grid item sm={12} alignItems="flex-start">
                  <CustomAutocomplete
                    name={"customerId"}
                    label={t("customer")}
                    skip={loading && filterLoad}
                    control={control}
                    errors={errors}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["customerId"],
                        "customer"
                      )
                    }
                    parseData={(data) => parseData(data)}
                    query={LIST_CUSTOMERS_DROPDOWN.query}
                    variables={{
                      input: {
                        active: true,
                        typeCode: ["MERCHANT"],
                      },
                    }}
                    defaultValue={autocompleteValues.customer}
                    disabled={codeValidation}
                  />
                </Grid>
              </Can>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  name={"productId"}
                  label={t("product")}
                  skip={loading && filterLoad}
                  control={control}
                  errors={errors}
                  parseData={(data) => parseData(data)}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["productId"],
                      "product"
                    )
                  }
                  query={LIST_Product_DROPDOWN.query}
                  variables={{
                    input: {
                      ...(watch("customerId") && {
                        customerId: watch("customerId"),
                      }),
                      active: true,
                    },
                  }}
                  defaultValue={autocompleteValues.product}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  multiple
                  valueKey="code"
                  control={control}
                  errors={errors}
                  skip={loading && filterLoad}
                  name={"statusCode"}
                  label={t("status")}
                  parseData={(data) => statusDataHandler(data)}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  variables={{
                    input: { code: "STATUS_IND" },
                  }}
                  defaultValue={autocompleteValues.statusCode}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  multiple
                  valueKey="code"
                  control={control}
                  errors={errors}
                  skip={loading && filterLoad}
                  name={"transactionTypeCode"}
                  label={t("transactionType")}
                  parseData={(data) => transactionTypeDataHandler(data)}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  variables={{
                    input: { code: "SHP_ACTION_TYPES" },
                  }}
                  onChangeValue={(e) => {
                    setValue("warehouseManifestId", "");
                  }}
                  defaultValue={autocompleteValues.transactionTypeCode}
                />
              </Grid>
              <Can permission={showManifest} showException>
                <Grid item sm={12} alignItems="flex-start">
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    hideCode={true}
                    skip={loading && filterLoad}
                    name={"warehouseManifestId"}
                    label={t("manifest")}
                    parseData={(data) => parseDataWarehouse(data)}
                    variables={{
                      input: {
                        transactionTypeCode: watch("transactionTypeCode")
                          ? watch("transactionTypeCode")
                          : [],
                      },
                    }}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["warehouseManifestId"],
                        "warehouseManifest"
                      )
                    }
                    query={LIST_WAREHOUSE_MANIFEST_DROPDOWN.query}
                    defaultValue={autocompleteValues.warehouseManifest}
                  />
                </Grid>
              </Can>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"quantity"}
                  label={t("quantity")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading && filterLoad}
                  name={"branchId"}
                  label={t("branch")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={[
          { name: "createdAt" },
          { name: "product" },
          { name: "quantity" },
          { name: "branch" },
          { name: "theShipment" },
          { name: "manifest" },
          { name: "transactionType" },
          { name: "status" },
          { name: "createdBy" },
        ]}
        tableBody={
          <TableBody>
            {listProductTransactions &&
              listProductTransactions?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    className={classes.tableBodyRow}
                  >
                    <FixedTableCell>{row?.date}</FixedTableCell>
                    <CellLink pathname={`/admin/products/${row?.product?.id}`}>
                      <Box
                        component="span"
                        fontWeight="bold"
                      >{` (${row?.product?.code}) `}</Box>
                      <Box component="span">{row?.product?.name}</Box>
                    </CellLink>
                    <FixedTableCell>{row?.quantity}</FixedTableCell>
                    <CellLink pathname={`/admin/branches/${row?.branch?.id}`}>
                      {row?.branch?.name}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/shipments/${row?.shipment?.id}`}
                    >
                      {row?.shipment?.code}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/warehouse-manifests/${row?.warehouseManifest?.id}`}
                    >
                      {row?.warehouseManifest?.code}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/transaction-types/${row?.transactionType?.id}`}
                    >
                      {row?.transactionType?.name}
                    </CellLink>
                    {/* <FixedTableCell>{row?.productStatus?.code}</FixedTableCell> */}
                    <CellLink
                      pathname={`/admin/lookup/${row?.status?.lookup?.id}`}
                    >
                      {row?.status?.name}
                    </CellLink>
                    <CellLink pathname={`/admin/users/${row?.createdBy?.id}`}>
                      {row?.createdBy?.username}
                    </CellLink>
                  </TableRow>
                );
              })}
            <TableRow>
              <FixedTableCell colSpan={1}> </FixedTableCell>
              <FixedTableCell variant="head">{t("total")}</FixedTableCell>
              <FixedTableCell variant="head">
                {data?.sumProductTransactions?.quantity}
              </FixedTableCell>
              <FixedTableCell colSpan={6}> </FixedTableCell>
            </TableRow>
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listProductTransactions?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listProductTransactions ? 0 : search?.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default ProductsTransactions;
