import {
    Avatar,
    Badge,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Typography
} from "@mui/material";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import config from "../config.json"
import GlobalSearch from "../Component/GlobalSearch/GlobalSearch";
import LogoutDialog from "../Component/Profile/LogoutDialog";
import { useTranslation } from "react-i18next";
import { Globals } from "../Component/HOC/Classes/Globals";
import ChangePassDialog from "../Component/Profile/ChangePassDialog";
import SpanLink from "../Component/HOC/CustomComponents/SpanLink";
import { AiOutlineDashboard } from "react-icons/ai";
import {  MdLogout, MdOutlineLock } from "react-icons/md";
import DarkModeIcon from "../Component/HOC/CustomComponents/DarkModeIcon";
import LanguageIcons from "../Component/HOC/CustomComponents/LanguageIcons";

function AvatarComponent(props) {
    const { addDashboard,
        addDashboardIcons,
        notificationIcon,
        messageIcon,
        badge,
        open,
        handleClick,
        handleCloseMenu,
        anchorEl
    } = props

    const [openLogout, setOpenLogout] = useState(false)
    const [openChangePass, setOpenChangePass] = useState(false)

    const { t } = useTranslation();

    return (
        <>
            <LogoutDialog setOpenLogout={setOpenLogout} openLogout={openLogout} />
            <ChangePassDialog setOpen={setOpenChangePass} open={openChangePass} />
            <Menu
                disableScrollLock={true}
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleCloseMenu}
                sx={{ zIndex: 1199 }}
                // onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        width: "250px",
                        borderRadius: "8px",
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '& ul': {
                            px: 3,
                            py: 3,
                        },
                        '& ul li,& ul a': {
                            px: "3px",
                        },
                        '& ul li:hover,& ul a:hover': {
                            borderRadius: "8px"
                        },
                        'p::first-letter, div::first-letter': {
                            textTransform: "capitalize"
                        }
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {addDashboard &&
                    <MenuItem component={Link} to="/admin" onClick={handleCloseMenu} sx={{ textTransform: "capitalize" }}>
                        <ListItemIcon>
                            <AiOutlineDashboard fontSize={20} />
                        </ListItemIcon>
                        {t("dashboard")}
                    </MenuItem>}
                {!addDashboard &&
                    <Stack spacing={2} direction="column" alignItems="center">
                        <Avatar sx={{ margin: "0 !important", height: '60px !important', width: '60px !important' }} />
                        {Globals?.user?.account ?
                            <SpanLink
                                pathname='/admin/profile'
                                onClick={handleCloseMenu}
                                sx={{
                                    fontSize: "18px"
                                }}
                            >
                                {Globals?.user?.account?.name ?? Globals?.user?.username}
                            </SpanLink> :
                            <Typography color={"text.primary"} variant="h6" noWrap >{Globals?.user?.account?.name ?? Globals?.user?.username}</Typography>
                        }
                    </Stack>
                }
                {!addDashboard && <Divider sx={{ my: 1 }} />}
                {addDashboardIcons &&
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-evenly">
                        <GlobalSearch props={props.props} handleCloseMenu={handleCloseMenu} />
                        {notificationIcon}
                        {messageIcon}
                    </Stack>
                }
                {addDashboardIcons && <Divider sx={{ my: 1 }} />}
                {config.app.languages.length > 1 && <LanguageIcons listItem handleCloseMenu={handleCloseMenu} />}
                <DarkModeIcon listItem />
                <MenuItem onClick={() => setOpenChangePass(true)}>
                    <ListItemIcon>
                        <MdOutlineLock fontSize={20} />
                    </ListItemIcon>
                    <Typography>{t("changePassword")}</Typography>
                </MenuItem>
                <MenuItem onClick={() => setOpenLogout(true)}>
                    <ListItemIcon>
                        <MdLogout fontSize={20} />
                    </ListItemIcon>
                    <Typography>{t("logout")}</Typography>
                </MenuItem>
            </Menu>

            <IconButton
                onClick={handleClick}
                size="medium"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                {addDashboardIcons ? <Badge
                    color="primary"
                    variant="dot"
                    invisible={!((badge.message === "false") || (badge.notification === "false"))}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <Avatar sx={{ width: 32, height: 32 }} />
                </Badge>
                    :
                    <Avatar sx={{ width: 32, height: 32 }} />
                }

            </IconButton>
        </>
    )
}

export default AvatarComponent