import {
  Box,
  Button,
  Icon,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  DeleteOutline,
  Edit,
  Image,
  LockOpenOutlined,
  Print,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CustomerPaymentMethod } from "./paymentDetails";
import {
  CUSTOMER_ID,
  DELETE_PAYMENT_MUTATION,
  DISAPPROVE_PAYMENT_MUTATION,
  PAYMENT_VIEW_CONCIERGE_DATA,
  PAYMENT_VIEW_SHIPMENT_DATA,
  PAYMENT_VIEW_PICKUP_DATA
} from "./PaymentListQuary";
import Grid from "@mui/material/Unstable_Grid2";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { dateFormatLL } from "../../helpers/dateFunctions";
import { GetPaymentPermissionSlug } from "../../helpers/getPermissionSlug";
import formatMoney from "../../helpers/numbersDot";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import config from "../../config.json";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import Tabs from "./Components/Tabs";

const PREFIX = "PaymentView";

const classes = {
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  viewPaper: `${PREFIX}-viewPaper`,
  title: `${PREFIX}-title`,
  table: `${PREFIX}-table`,
  button: `${PREFIX}-button`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  overlay: `${PREFIX}-overlay`,
  iconColor: `${PREFIX}-iconColor`,
  totalPaper: `${PREFIX}-totalPaper`,
  totalTitle: `${PREFIX}-totalTitle`,
  main: `${PREFIX}-main`,
  alertMessage: `${PREFIX}-alertMessage`,
  viewBody: `${PREFIX}-viewBody`,
  root: `${PREFIX}-root`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classes.root}`]: {
    justifyContent: "flex-end"
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classes.viewPaper}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },
  [`& .${classes.totalPaper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    // minHeight: "327px",
    gridAutoRows: "max-content",
  },
  [`& .${classes.totalTitle}`]: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    // fontSize:
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.alertMessage}`]: {
    flexGrow: 1,
    overflow: "hidden",
    alignItems: "center"
  },
  [`& .${classes.viewBody}`]: {
    // height: "calc(100vh - 64px - 48px - 40px)",
    // overflowY: "scroll",
    padding: theme.spacing(2),
    // [theme.breakpoints.down("sm")]: {
    //   height: "calc(100dvh - 56px - 48px - 40px))",
    // },
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const PaymentViewBody = (props) => {
  const { t } = useTranslation();
  const { paymentType, data, refetch, paymentByIdLoad, ALLOWED_CONCIERGE, ALLOWED_SHIPMENT, ALLOWED_PICKUP, localKeys, shipmentListDetails, setKeys, paymentsData } = props
  const paymentId = props.match.params.id?.trim();
  const [shipmentData, setShipmentData] = useState({
    page: 0,
    total: 0,
    rowsPerPage: config.app.pageSize,
    pickedShipment: []
  });
  const [conciergeRequestsData, setConciergeRequestsData] = useState({
    page: 0,
    total: 0,
    noData: true,
    rowsPerPage: config.app.pageSize,
    pickedConciergeRequests: []
  });
  const [pickupData, setPickupData] = useState({
    page: 0,
    total: 0,
    noData: true,
    rowsPerPage: config.app.pageSize,
    pickedPickup: []
  });
  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    confirmAction: true,
    title: "",
    content: "",
  });
  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
  };
  const openSignatureDialog = (imgPath) => {
    setDialogDetails((prev) => ({
      state: true,
      title: null,
      content: (
        <Box sx={{ background: "#fff", width: "100%" }} component="img" alt="signature" src={getBackendUri(imgPath.path)} />
      ),
      confirmAction: false,
      function: () => { },
    }));
  };
  /******************************************* Start Pagination Functions ********************************************/

  useEffect(() => {
    if (data?.payment === null) return;
    const payment = data?.payment;
    if (ALLOWED_SHIPMENT) {
      setShipmentData(prev => ({
        ...prev,
        total: payment.entriesShipment.paginatorInfo.total
      }))
      getShipmentData()
    }

    if (ALLOWED_CONCIERGE) {
      setConciergeRequestsData(prev => ({
        ...prev,
        total: payment.entriesConcierge.paginatorInfo.total
      }))
    }
    if (ALLOWED_PICKUP) {
      setPickupData(prev => ({
        ...prev,
        total: payment.entriesPickup.paginatorInfo.total
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  /******************************************* Start payment By Id ********************************************/
  const user = Globals.user;
  const isUser = Boolean(user.account)
  const LIST_CUSTOMER_PERMISSION = Globals.user.hasPermission("shipping.customer_merchant.list")

  const [getShipmentData, { loading: loadingShipmentData }] = useLazyQuery(
    gql`
        ${PAYMENT_VIEW_SHIPMENT_DATA(shipmentListDetails ?? []).query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(paymentId),
        typeCode: "SHIPMENT",
        page: shipmentData.page + 1,
        first: shipmentData.rowsPerPage
      },
      onCompleted: (data) => {
        if (data?.payment === null) return;
        const payment = data?.payment;
        const shipments = payment.entries.data
        setShipmentData(prev => {
          return {
            ...prev,
            pickedShipment: shipments,
          }
        })
      },
    }
  );

  const [getPickupData, { loading: loadingPickupData }] = useLazyQuery(
    gql`
        ${PAYMENT_VIEW_PICKUP_DATA.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(paymentId),
        typeCode: "PICKUP",
        page: pickupData.page + 1,
        first: pickupData.rowsPerPage
      },
      onCompleted: (data) => {
        if (data?.payment === null) return;
        const payment = data?.payment;
        const pickupRequests = payment.entries.data
        setPickupData(prev => {
          return {
            ...prev,
            noData: false,
            pickedPickup: pickupRequests
          }
        })
      },
    }
  );

  const [getConciergeData, { loading: loadingConciergeData }] = useLazyQuery(
    gql`
        ${PAYMENT_VIEW_CONCIERGE_DATA.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(paymentId),
        typeCode: "CONCIERGE_REQUEST",
        page: conciergeRequestsData.page + 1,
        first: conciergeRequestsData.rowsPerPage
      },
      onCompleted: (data) => {
        if (data?.payment === null) return;
        const payment = data?.payment;
        const conciergeRequests = payment.entries.data

        setConciergeRequestsData(prev => {
          return {
            ...prev,
            noData: false,
            pickedConciergeRequests: conciergeRequests
          }
        })
      },
    }
  );
  
  const { data: customerData } = useQuery(
    gql`
      ${CUSTOMER_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !data?.payment?.customer?.id || paymentType === "DLVBY" || !LIST_CUSTOMER_PERMISSION,
      variables: {
        id: parseInt(data?.payment?.customer?.id),
      },
    }
  );

  const branchId = data?.payment?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;
  const canDelete =
    user.hasPermission(GetPaymentPermissionSlug(paymentType?.toLowerCase(), 'delete')) &&
    canAccessBranch &&
    !data?.payment?.approved &&
    !paymentByIdLoad;

  const canEdit =
    user.hasPermission(GetPaymentPermissionSlug(paymentType?.toLowerCase(), 'update')) &&
    canAccessBranch &&
    !data?.payment?.glApproved &&
    !paymentByIdLoad;

  const canDisapprove =
    !paymentByIdLoad &&
    user.hasPermission(GetPaymentPermissionSlug(paymentType?.toLowerCase(), 'disapprove')) &&
    canAccessBranch &&
    data?.payment?.approved;

  /******************************************* End payment List ********************************************/

  let viewBody = null;

  const [openDelete, setOpenDelete] = useState(false);
  const [openDisapprove, setOpenDisapprove] = useState(false);

  const openDeleteDialog = (type) => {
    setOpenDelete(true)
  };
  const openDisapproveDialog = (type) => {
    setOpenDisapprove(true)
  };

  const editURL = () => {
    pushUrl(props, `/admin/payments/${paymentId}/edit`);
  }

  const icons = [
    {
      id: "print",
      title: "print",
      action: () =>
        window.open(
          `${window.location.origin}/report/print/payment/${paymentId}/${paymentType}`
        ),
      icon: Print,
      disabled: paymentByIdLoad
    },
    {
      id: "edit",
      title: "edit",
      action: editURL,
      icon: Edit,
      permission: canEdit,
    },
    {
      id: "delete",
      title: "delete",
      action: openDeleteDialog,
      icon: DeleteOutline,
      permission: Boolean(canDelete),
    },
    {
      id: "disapprove",
      title: "disapprove",
      action: openDisapproveDialog,
      icon: LockOpenOutlined,
      permission: Boolean(canDisapprove),
    },
  ]


  viewBody = (
    <Stack>
      <CustomDialog
        title={dialogDetails.title}
        fullWidth
        maxWidth="xs"
        onClose={closeConfirmationDialog}
        content={dialogDetails.content}
        open={dialogDetails.state}
        actions={
          dialogDetails.function !== null && (
            <>
              <Button color="primary" onClick={closeConfirmationDialog}>
                {dialogDetails.confirmAction ? t("cancel") : t("close")}
              </Button>
              {dialogDetails.confirmAction && (
                <Button
                  color="primary"
                  disabled={paymentByIdLoad}
                  onClick={dialogDetails.function}
                >
                  {paymentByIdLoad ? (
                    <ButtonLoading />
                  ) : (
                    t("confirm")
                  )}
                </Button>
              )}
            </>
          )
        }
      >
        {dialogDetails.function === null && " "}
      </CustomDialog>
      <Paper
        container
        className={classes.viewPaper}
        sx={{ flexGrow: 1 }}
        component={Grid}
      >
        <KeyValuePair
          title={t("recordCode")}
          value={data?.payment?.code}
        />
        <KeyValuePair
          title={t("theDate")}
          value={dateFormatLL(data?.payment?.date)}
        />
        <KeyValuePair
          title={t("branch")}
          value={
            data?.payment?.branch?.name && (
              <SpanLink
                pathname={`/admin/branches/${data?.payment?.branch?.id}`}
              >
                {data?.payment?.branch?.name}
              </SpanLink>
            )
          }
        />
        <KeyValuePair
          title={t("safe")}
          value={
            data?.payment?.safe?.name && (
              <SpanLink
                pathname={`/admin/safes/${data?.payment?.safe?.id}`}
              >
                {data?.payment?.safe?.name}
              </SpanLink>
            )
          }
        />
        {!isUser && <KeyValuePair
          title={t("journalEntry")}
          value={
            data?.payment?.journalEntry?.code && (
              <SpanLink
                pathname={`/admin/finance/journal-entry/${data?.payment?.journalEntry?.id}`}
              >
                {data?.payment?.journalEntry?.code}
              </SpanLink>
            )
          }
        />}
        {data?.payment?.deliveryAgent ? (
          <KeyValuePair
            title={t("shippingAgent")}
            value={
              data?.payment?.deliveryAgent?.name && (
                <SpanLink
                  pathname={`/admin/delivery-agents/${data?.payment?.deliveryAgent?.id}`}
                >
                  {data?.payment?.deliveryAgent?.name}
                </SpanLink>
              )
            }
          />
        ) : null}
        <KeyValuePair
          title={t("transactionType")}
          value={
            <SpanLink
              pathname={`/admin/transaction-types/${data?.payment?.transactionType?.id}`}
            >
              {data?.payment?.transactionType?.name}
            </SpanLink>
          }
        />
        {data?.payment?.customer ? (
          <KeyValuePair
            title={t("customer")}
            value={
              data?.payment && (
                <SpanLink
                  pathname={`/admin/customers/${data?.payment?.customer?.id}`}
                >
                  {data?.payment?.customer?.name}
                </SpanLink>
              )
            }
          />
        ) : null}

        <KeyValuePair title={t("notes")} value={data?.payment?.notes} />
        {!isUser && <KeyValuePair
          title={t("createdBy")}
          value={
            data?.payment && (
              <SpanLink
                pathname={`/admin/users/${data?.payment?.createdBy?.id}`}
              >
                {data?.payment?.createdBy?.username}
              </SpanLink>
            )
          }
        />}
        {!isUser && <KeyValuePair
          title={t("updatedBy")}
          value={
            data?.payment && (
              <SpanLink
                pathname={`/admin/users/${data?.payment?.updatedBy?.id}`}
              >
                {data?.payment?.updatedBy?.username}
              </SpanLink>
            )
          }
        />}

        <KeyValuePair
          title={t("approved")}
          value={
            data?.payment?.approved ? (
              <Icon className={classes.iconColor}>
                check_circle_outline
              </Icon>
            ) : (
              <Icon color="error">highlight_off</Icon>
            )
          }
        />
        {!isUser && <KeyValuePair
          title={t("glApproved")}
          value={
            data?.payment?.glApproved ? (
              <Icon className={classes.iconColor}>
                check_circle_outline
              </Icon>
            ) : (
              <Icon color="error">highlight_off</Icon>
            )
          }
        />}
        {paymentType === 'CUSTM' &&
          (data?.payment?.images.length > 0 ?
            (<KeyValuePair
              title={t("image")}
              value={
                <IconButton
                  size="small"
                  onClick={() => openSignatureDialog(data?.payment?.images[0])}
                >
                  <Image fontSize="small" />
                </IconButton>
              }
            />) :
            (<KeyValuePair title={t("image")}>
            </KeyValuePair>))}

        {LIST_CUSTOMER_PERMISSION &&
          customerData &&
          <Grid xs={12}>
            <CustomerPaymentMethod classes={classes} customerData={customerData} />
          </Grid>
        }
      </Paper>
    </Stack>
  );

  return paymentByIdLoad ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !data?.payment ? (
      <NotFound />
    ) :
      (
        <Root>
          <MutationWithDialog
            mutaion={DELETE_PAYMENT_MUTATION.query}
            setOpenDelete={setOpenDelete}
            openDelete={openDelete}
            dialogTitle={t("deleteRecord")}
            dialogContent={t("deleteRecordMessage")}
            mutaionProps={{ variables: { id: parseInt(paymentId) } }}
            onCompleted={() =>
              pushUrl(props, `/admin/payments/${paymentType.toLowerCase()}`)
            }
            onCompleteMessage={t("successfullyDeletedRecord")}
          />

          <MutationWithDialog
            mutaion={DISAPPROVE_PAYMENT_MUTATION.query}
            setOpenDelete={setOpenDisapprove}
            openDelete={openDisapprove}
            dialogTitle={t("disapprove")}
            dialogContent={t("disapprovedMessage")}
            mutaionProps={{ variables: { id: parseInt(paymentId) } }}
            onCompleted={() => refetch()}
            onCompleteMessage={t("successfullyDisapproved")}
          />
          <TitleAppBar path={props.match.path} type={paymentType}>
            <LongMenu icons={icons} />
          </TitleAppBar>
          {props.children}

          <Stack direction={"column"} spacing={2} className={classes.viewBody}>
            <Stack>
              {viewBody}
            </Stack>
            {paymentType === 'DLVBY' &&
              <Paper sx={{ py: 2 }}>
                <Stack direction={"row"} spacing={1} flexWrap={"wrap"} justifyContent={"center"}>
                  <Typography
                    className={classes.totalTitle}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                    sx={{ textAlign: "end" }}
                  >
                    {t("totalDeservedAmount")}
                  </Typography>
                  <Typography

                    color="inherit"
                    variant="subtitle1"
                    component="div"
                    sx={{ textAlign: "start" }}
                  >
                    {formatMoney(data?.payment?.sumEntries?.paymentAmount)}
                  </Typography>
                </Stack>
              </Paper>
            }

            <Tabs
              paymentType={paymentType}
              paymentsData={paymentsData}
              classes={classes}

              paymentId={paymentId}
              setLocalDataKey={setKeys}
              localKeys={localKeys}


              loadingShipmentData={loadingShipmentData}
              shipmentData={shipmentData}
              setShipmentData={setShipmentData}

              conciergeRequestsData={conciergeRequestsData}
              setConciergeRequestsData={setConciergeRequestsData}
              getConciergeData={getConciergeData}
              loadingConciergeData={loadingConciergeData}

              loadingPickupData={loadingPickupData}
              getPickupData={getPickupData}
              pickupData={pickupData}
              setPickupData={setPickupData}
            />
            {/* {paymentShipmentsDetails && tableBodyShipments}
            {paymentShipmentsDetails && paymentType === 'DLVBY' && tableBodyPickups}
            {paymentShipmentsDetails && paymentType === 'DLVBY' && tableBodyConciergeRequests} */}
          </Stack>
        </Root>
      );
};

export default PaymentViewBody;
