import * as gqlb from "gql-query-builder";
export const SAVE_CUSTOMER = gqlb.mutation({
  operation: "saveCustomer",
  fields: ["id", "code", "active", "specifyShipmentCode"],
  variables: {
    input: {
      type: "CustomerInput",
      required: true,
    },
  },
});

export const LIST_CUSTOMERS = gqlb.query({
  operation: "listCustomers",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "createdAt",
        "name",
        "active",
        "phone",
        "mobile",
        "email",
        "representative",
        "paymentCycleDays",
        {
          zone: ["id", "name"],
        },
        {
          subzone: ["name"],
        },
        {
          branch: ["name", "id"],
        },
        { paymentCycle: ["name", "id"] },
        {
          paymentMethod: ["name", "id", "code"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListCustomersFilterInput",
    },
  },
});

export const customerDueInquiryFields = (paymentDuePermission) => {
  return [
    "id",
    "code",
    "name",
    "phone",
    "mobile",
    "representative",
    "vodafoneCash",
    "accountNumber",
    `paymentDueAmount @include(if:${paymentDuePermission})`,
    {
      branch: ["name", "id"],
    },
    {
      paymentMethod: ["name", "id", "code"],
    },
    {
      bank: ["name", "id"],
    },
  ];
};
export const LIST_CUSTOMERS_DEU_INQUIRY = (paymentDuePermission) =>
  gqlb.query({
    operation: "listCustomers",
    fields: [
      {
        operation: "paginatorInfo",
        fields: [
          "count",
          "currentPage",
          "firstItem",
          "hasMorePages",
          "lastItem",
          "lastPage",
          "perPage",
          "total",
        ],
        variables: {},
      },
      {
        operation: "data",
        fields: customerDueInquiryFields(paymentDuePermission),
        variables: {},
      },
    ],
    variables: {
      page: {
        type: "Int",
      },
      first: {
        type: "Int",
      },
      input: {
        type: "ListCustomersFilterInput",
      },
    },
  });

export const CUSTOMERID = gqlb.query([
  {
    operation: "customer",
    fields: [
      "id",
      "code",
      "createdAt",
      "name",
      "address",
      "active",
      "taxable",
      "phone",
      "mobile",
      "postalCode",
      "mailBox",
      "email",
      "vodafoneCash",
      "accountNumber",
      "representative",
      "activityName",
      "specifyShipmentCode",
      "shareCollectedFees",
      "payUncollected",
      "warehousing",
      "sendDeliveryOtp",
      "paymentCycleDays",
      "idNumber",
      "taxCardNumber",
      {
        hideMobileFrom: ["id", "code", "name"],
      },
      {
        createdBy: ["id", "username"],
      },
      {
        zone: ["id", "name"],
      },
      {
        subzone: ["id", "name"],
      },
      {
        branch: ["id", "name"],
      },
      {
        bank: ["id", "name"],
      },
      {
        country: ["id", "name"],
      },
      {
        state: ["id", "name"],
      },
      {
        city: ["id", "name"],
      },
      {
        area: ["id", "name"],
      },
      {
        glAccount: ["id", "name"],
      },
      {
        priceList: ["id", "name"],
      },
      {
        type: ["code", "name"],
      },
      {
        category: ["code", "name"],
      },
      {
        paymentMethod: ["code", "name", "code"],
      },
      {
        defaultShipmentType: ["code", "name"],
      },
      {
        paymentTypes: ["code", "id", "name"],
      },
      {
        defaultPriceType: ["code", "name"],
      },
      {
        defaultOpenable: ["code", "name"],
      },
      {
        users: ["id", "username"],
      },
      {
        paymentCycle: ["code", "name"],
      },
      {
        identificationType: ["code", "name"],
      },
      {
        integration: ["id"],
      },
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
  {
    operation: "listPaymentTypesDropdown",
    fields: ["id", "name", "code"],
    variables: {
      input: {
        type: "ListPaymentTypesDropdownInput",
      },
    },
  },
]);

export const SETTINGS = gqlb.query({
  operation: "shippingSettings",
  fields: [
    { mainCustomerGLAccount: ["id", "name"] },
    { mainConsigneeGLAccount: ["id", "name"] },
    "warehousing ",
  ],
  variables: {},
});

export const GetPaymentCycleDays = (t, type) => {
  let arr = [];
  for (let j = 1; j <= 31; j++) {
    arr.push({
      id: j,
      name: j.toString(),
    });
  }

  const ListPaymentCycleDays = {
    WEEKLY: [
      {
        id: 6,
        name: t("saturday"),
      },
      {
        id: 7,
        name: t("sunday"),
      },
      {
        id: 1,
        name: t("monday"),
      },
      {
        id: 2,
        name: t("tuesday"),
      },
      {
        id: 3,
        name: t("wednesday"),
      },
      {
        id: 4,
        name: t("thursday"),
      },
      {
        id: 5,
        name: t("friday"),
      },
    ],
    MONTHLY: arr,
  };

  return type ? ListPaymentCycleDays[type] : [];
};
