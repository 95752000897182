// import { } from 'pagedjs/dist/paged.polyfill';
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";

import "moment/locale/ar";
import config from "./config.json";
import ModeContextProvider from "./Context/ModeContext";
import { Provider } from "react-redux";
import { store } from "./store/store";

const container = document.getElementById("root");
const root = createRoot(container);

// if (process.env.NODE_ENV === 'production') {
//   console.log = () => { }
// }

root.render(
  <BrowserRouter basename={config.app.basePath}>
    <Suspense fallback={""}>
      <ModeContextProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </ModeContextProvider>
    </Suspense>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
