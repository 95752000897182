import {
    ClickAwayListener,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListSubheader,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
    FlagImage,
} from "react-international-phone";
import PropTypes from "prop-types";
import countries from '../../../phoneNumber.json'
import { Globals } from "../Classes/Globals";
import { styled } from "@mui/material/styles";
import EmptyTableMessage from "../FunctionComponents/EmptyTableMessage";
import { ArrowDropDown } from "@mui/icons-material";
import clsx from "clsx";

const TextFieldStyle = styled(TextField)(({ theme }) => ({
    ".MuiInputBase-root": { paddingLeft: "6px", paddingRight: "8px" },
    ".MuiInputBase-input": { paddingRight: "2px" },
}));

const PREFIX = "MuiPhone";

const classes = {
    countryContent: `${PREFIX}-countryContent`,
    countryCodeSetting: `${PREFIX}-countryCodeSetting`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
    position: "relative",

    [`& .${classes.countryContent}`]: {
        cursor: "pointer"
    },
    [`& .${classes.countryCodeSetting}`]: {
        borderRadius: "3px",
        backgroundColor: "#424344",
        minHeight: "20px",
        border: "none",
        borderBottom: "1px solid #c7c7c7",
    },

}));

const MuiPhone = (props) => {
    const {
        name,
        control,
        defaultValue,
        readOnly,
        inputProps,
        rules,
        onChange,
        variant,
        serverValidation,
        label,
        selectName,
        mobileData,
        countryCodeValue,
        settingPhoneKey,
        setValue,
        codeValue,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const {
        formState: { errors },
        field: { ref, onChange: fieldChange, value, ...fieldProps },
    } = useController({
        name,
        control,
        rules: {
            ...rules,
            ...(rules && {
                validate: {
                    whiteSpace: (value) => {
                        if (value && typeof value === "string") {
                            return !!value.trim() || t("fieldIsRequired");
                        }
                    },
                    ...(rules["validate"] && rules["validate"]),
                },
            }),
        },
        defaultValue: defaultValue ?? "",
    });

    const errorName = name.includes(".") && name.split(".");
    const serverError = errorName ? errorName[1] : name;
    const fieldError = errorName
        ? errors?.[errorName[0]]?.[errorName[1]]
        : errors?.[name];
    const isRequired =
        (rules && rules?.required) ||
        (rules?.validate?.max && typeof rules?.validate?.max() === "string") ||
        (rules?.validate?.require &&
            typeof rules?.validate?.require() === "string");

    const phoneNumberRegex = /^\d+$/

    return (
        <TextFieldStyle
            inputRef={ref}
            type="tel"
            dir="ltr"
            {...fieldProps}
            {...restProps}
            label={isRequired ? label + " *" : label}
            defaultValue={defaultValue}
            value={value}
            autoComplete="off"
            id={name}
            variant={variant || "filled"}
            fullWidth
            multiline={!!props.rows}
            error={Boolean(fieldError || serverValidation?.[serverError])}
            helperText={
                errors
                    ? fieldError?.message
                    : Boolean(serverValidation) && serverValidation?.[serverError]
                        ? serverValidation?.[serverError][0]
                        : null
            }
            inputProps={{
                readOnly: readOnly,
                maxLength: 15,
                ...inputProps,
            }}
            onChange={(e) => {
                let value = e.target.value
                const valid = phoneNumberRegex.test(value)
                const empty = value === ""
                if (!valid && !empty) {
                    return
                }
                fieldChange(e);
                onChange && onChange(e);
            }}
            size="small"
            InputProps={{
                startAdornment: (
                    <InputAdornment
                        position="start"
                        style={{ margin: 0 }}
                    >
                        {/* <MUIselect {...props} TextFieldChange={fieldChange} /> */}
                        <CountriesCodeComponent {...props} setValue={setValue} codeValue={codeValue} TextFieldChange={fieldChange} />
                    </InputAdornment>
                )
            }}
            {...restProps}
        />
    );
};


export const CountriesCodeComponent = (props) => {
    const {
        countryCodeValue,
        settingPhoneKey,
        selectName,
        codeValue,
        setValue,
        setting,
    } = props;
    const { t, i18n } = useTranslation()
    const [country, setCountry] = useState(null)
    const [countriesAllowed, setCountriesAllowed] = useState(countries)

    const name = selectName

    const countryCode = Globals?.settings?.countryCode ?? countryCodeValue
    countriesAllowed.sort(function (a, b) {
        return i18n.language === "ar" ? a.labelAr.localeCompare(b.labelAr, ["ar"]) : a.label.localeCompare(b.label, ["en"])
    });
    const allowPhoneKey = Globals?.settings?.allowPhoneKey ?? settingPhoneKey
    useEffect(() => {
        if (codeValue) {
            const valueAsNumber = codeValue.includes("+") ? true : false
            const countryFound = valueAsNumber ? countries.filter((country) => country.phone === codeValue.substring(1)) :
                countries.filter((country) => country.code === codeValue.toUpperCase())

            setCountry(countryFound[0])
            if (setting) {
                setValue(name, countryFound[0].phone)
            } else {
                setValue(name, countryFound[0].code)
            }
        } else {
            const countryFound = countries.filter((country) => country.phone === countryCode?.substring(1))
            !setting && setValue(name, countryFound?.[0]?.code)
            setCountry(countryFound?.[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode, codeValue])

    const replacements = [
        {
            char: "ا",
            alternatives: ["أ", "إ"],
        },
        {
            char: "ه",
            alternatives: ["ة"],
        },
        {
            char: "ي",
            alternatives: ["ى"],
        },
    ];

    const sanitize = (token) => {
        replacements.forEach((replacement) =>
            replacement.alternatives.forEach(
                (alternative) => (token = token.replace(alternative, replacement.char))
            )
        );

        return token;
    };


    const handleClose = () => {
        setCountriesAllowed(countries)
        handleClick()
    }

    function handleSetCountries(filteredList) {
        if (filteredList) {
            setCountriesAllowed(filteredList)
        } else {
            setCountriesAllowed(countries)
        }
    }

    const handleChangeFilter = (filter) => {
        if (!filter) {
            setCountriesAllowed(countries)
            return
        }

        filter = filter.replace("+", "")
        const filteredList = countries.filter(country => country.phone.includes(filter) ||
            (i18n.language === "ar" ? sanitize(country.labelAr).includes(sanitize(filter)) : country.label.includes(filter)));

        handleSetCountries(filteredList)
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);

    const id = open ? 'simple-popper' : undefined;
    const dir = i18n.language === "ku" ? "rtl" : i18n.dir()

    return (
        <Root>
            {open &&
                <ClickAwayListener onClickAway={handleClick}>
                    <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: 1500 }}>
                        <Paper>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                    position: 'relative',
                                    overflow: 'auto',
                                    maxHeight: 400,
                                    minWidth: 300,
                                    '& ul': { padding: 0 },
                                    ...(dir === "rtl" && { direction: "ltr" })
                                }}
                                subheader={<li />}
                            >
                                <li>
                                    <ul>
                                        <ListSubheader sx={{ p: 2, }}>
                                            <Stack>
                                                <TextField
                                                    fullWidth
                                                    label={t("search")}
                                                    size="small"
                                                    onChange={(e) => handleChangeFilter(e.target.value)}
                                                    autoComplete='off'
                                                    inputRef={input => input && input.focus()}
                                                />
                                            </Stack>
                                        </ListSubheader>

                                        {countriesAllowed.length ? countriesAllowed.map((country, index) => (
                                            <ListItem key={index} component="div" disablePadding>
                                                <ListItemButton onClick={() => {
                                                    if (setting) {
                                                        setValue(name, `+${country.phone}`)
                                                    } else {
                                                        setValue(name, country.code)
                                                    }
                                                    setCountry(country)
                                                    handleClose()
                                                }} sx={{ py: 1 }}>
                                                    <Stack direction={"row"} alignItems={"center"}>
                                                        <FlagImage
                                                            iso2={country.code === "IL" ? "ps" : country.code.toLowerCase()}
                                                            style={{ marginRight: "8px" }}
                                                            height={16}
                                                        />
                                                        <Typography mx="8px">{i18n.language === "ar" ? country.labelAr : country.label}</Typography>
                                                        <Typography color="gray">+{country.phone}</Typography>
                                                    </Stack>
                                                </ListItemButton>
                                            </ListItem>
                                        )) : <EmptyTableMessage
                                            loading={false}
                                            message={t("noResult")}
                                        />}
                                    </ul>
                                </li>

                            </List>
                        </Paper>
                    </Popper>
                </ClickAwayListener>

            }
            {allowPhoneKey && !setting &&
                <Stack aria-describedby={id} direction={"row"} pt={"18px"} onClick={handleClick} className={classes.countryContent}>
                    <Stack width={20}>
                        <FlagImage iso2={country?.code === "IL" ? "ps" : country?.code.toLowerCase()} style={{ display: "flex", width: "100%" }} height={20} />
                    </Stack>
                    <Typography mx={1} color={"text.primary"}>+{country?.phone}</Typography>
                </Stack>
            }

            {setting &&
                <Stack
                    aria-describedby={id}
                    border={(theme) => `1px solid ${theme.palette.divider}`}

                    px={1.5}
                    py={0.5}
                    borderRadius={2}
                    spacing={0.5}
                    onClick={handleClick}
                    className={clsx(classes.countryContent, classes.countryCodeSetting)}
                >
                    <Typography variant="caption">{t("countryCode")}</Typography>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Stack width={20}>
                                <FlagImage iso2={country?.code === "IL" ? "ps" : country?.code?.toLowerCase()} style={{ display: "flex", width: "100%" }} height={18} />
                            </Stack>
                            <Typography variant="body2" color={"text.primary"}>{i18n.language === "ar" ? country?.labelAr : country?.label}</Typography>
                            <Typography variant="body2" color={"text.primary"} sx={{ direction: dir === "rtl" ? "rtl" : "ltr" }}>+{country?.phone}</Typography>
                        </Stack>
                        <ArrowDropDown />
                    </Stack>
                </Stack>
            }
        </Root>
    )
}

// const TypographyStyle = styled(Typography)(({ theme }) => ({
//     marginRight: theme.spacing(1),
//     marginLeft: theme.spacing(0.8),
// }));

// const MUIselect = (props) => {
//     const {
//         control,
//         selectName,
//         disabled,
//         onChanges,
//         rules,
//         readOnly,
//         defaultValue,
//         TextFieldChange,
//         countryCodeValue,
//     } = props;
//     const name = selectName
//     const {
//         formState: { errors },
//         field: { ref, value: val, onChange: fieldChange, ...fieldProps },
//     } = useController({
//         name,
//         control,
//         rules: { ...rules },
//         defaultValue: countries && defaultValue ? defaultValue : "",
//     });

//     const { i18n } = useTranslation()

//     const errorName = name.includes(".") && name.split(".");
//     const fieldError = errorName
//         ? errors?.[errorName[0]]?.[errorName[1]]
//         : errors?.[name];

//     const countryCode = Globals?.settings?.countryCode ?? countryCodeValue
//     const multiCountries = Globals?.settings?.multiCountries


//     useEffect(() => {
//         if (!val) {
//             const country = countries.filter((country) => "+" + country.phone === countryCode)
//             fieldChange(country[0].code.toLowerCase())
//             return () => { }
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [])

//     countries.sort(function (a, b) {
//         return i18n.language === "ar" ? a.labelAr.localeCompare(b.labelAr, ["ar"]) : a.label.localeCompare(b.label, ["en"])
//     });

//     return (
//         <Select
//             variant="outlined"
//             {...fieldProps}
//             inputProps={{
//                 readOnly: readOnly,
//                 IconComponent: () => null
//             }}
//             dir="ltr"
//             inputRef={ref}
//             error={Boolean(fieldError)}
//             value={val}
//             defaultValue={countries && defaultValue ? defaultValue : ""}
//             defaultChecked
//             disabled={disabled}
//             onChange={(e) => {
//                 fieldChange(e.target.value);
//                 TextFieldChange("")
//                 onChanges && onChanges(e);
//             }}
//             MenuProps={{
//                 style: {
//                     height: "300px",
//                     width: "360px",
//                     top: "10px",
//                     right: "80%",
//                 },
//                 transformOrigin: {
//                     vertical: "top",
//                     horizontal: "left"
//                 }
//             }}
//             sx={{
//                 width: "max-content",
//                 ...(!multiCountries && {
//                     width: 0,
//                     visibility: "hidden",
//                 }),
//                 boxShadow: "none",
//                 ".MuiOutlinedInput-notchedOutline": { border: 0 },
//                 "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
//                 {
//                     border: 0,
//                 },
//                 "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
//                 {
//                     border: 0,
//                 },
//                 "&.MuiOutlinedInput-root":
//                 {
//                     padding: 0,
//                 },
//                 // Remove default outline (display only on focus)
//                 fieldset: {
//                     display: "none"
//                 },
//                 '&.Mui-focused:has(div[aria-expanded="false"])': {
//                     fieldset: {
//                         display: "block"
//                     }
//                 },
//                 // Update default spacing
//                 ".MuiSelect-select": {
//                     padding: "1px !important",
//                 },
//                 svg: {
//                     right: 0
//                 }
//             }}
//             renderValue={(value) => {
//                 const country = countries.filter((country) => country.code === value.toUpperCase())
//                 return <Box sx={{ display: "flex", alignItems: "center" }}>
//                     <FlagImage iso2={value === "il" ? "ps" : value} style={{ display: "flex" }} height={20} />
//                     <TypographyStyle color={"text.primary"}>+{country[0].phone}</TypographyStyle>
//                 </Box>
//             }
//             }
//         >
//             {countries.map((c) => {
//                 return (
//                     <MenuItem key={c.code} value={c.code.toLowerCase()}>
//                         <FlagImage
//                             iso2={c.code === "IL" ? "ps" : c.code.toLowerCase()}
//                             style={{ marginRight: "8px" }}
//                             height={16}
//                         />
//                         <Typography mx="8px">{i18n.language === "ar" ? c.labelAr : c.label}</Typography>
//                         <Typography color="gray">+{c.phone}</Typography>
//                     </MenuItem>
//                 );
//             })}
//         </Select>
//     );
// };

// MUIselect.propTypes = {
//     control: PropTypes.any,
//     errors: PropTypes.any,
//     data: PropTypes.array,
//     name: PropTypes.string,
//     label: PropTypes.string,
//     onChanges: PropTypes.func,
//     rules: PropTypes.object,
//     readOnly: PropTypes.bool,
//     disabled: PropTypes.bool,
//     defaultValue: PropTypes.any,
// };



MuiPhone.propTypes = {
    errors: PropTypes.any,
    name: PropTypes.string.isRequired,
    control: PropTypes.any.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.any,
    selectName: PropTypes.string.isRequired,
};

export default MuiPhone;
