import {
    updateDarkModeAsync,
    updateExportInvoiceAsync,
    updateExportPaymentCUSTMAsync,
    updateExportPaymentDLVBYAsync,
    updateLanguageAsync,
    updateExportCollectionCUSTMAsync,
    updateExportCollectionDLVBYAsync
} from "../thunk/preferencesThunks";

const addAsyncReducer = (builder, action, stateKey) => {
    builder
        .addCase(action.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(action.fulfilled, (state, { payload }) => {
            state[stateKey] = payload;
            state.status = 'succeeded';
        })
        .addCase(action.rejected, (state, { payload }) => {
            state.status = 'failed';
            state.error = payload;
        });
};

// Usage:
export function extraReducersBuilder(builder) {
    addAsyncReducer(builder, updateDarkModeAsync, 'darkMode');
    addAsyncReducer(builder, updateLanguageAsync, 'language');
    addAsyncReducer(builder, updateExportInvoiceAsync, 'exportInvoice');
    addAsyncReducer(builder, updateExportPaymentCUSTMAsync, 'exportPaymentCUSTM');
    addAsyncReducer(builder, updateExportPaymentDLVBYAsync, 'exportPaymentDLVBY');
    addAsyncReducer(builder, updateExportCollectionCUSTMAsync, 'exportCollectionCUSTM');
    addAsyncReducer(builder, updateExportCollectionDLVBYAsync, 'exportCollectionDLVBY');
}
