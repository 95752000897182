import { createContext, useContext } from "react";

export const PaymentPickupsContext = createContext({});

const PaymentPickupsProvider = (props) => {
  const { value } = props;
  return (
    <PaymentPickupsContext.Provider value={value}>
      {props.children}
    </PaymentPickupsContext.Provider>
  );
};

export default PaymentPickupsProvider;

export const usePaymentPickupsContext = () => {
  const paymentPickupsContext = useContext(PaymentPickupsContext);

  return {
    paymentPickupsContext,
  };
};
