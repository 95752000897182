import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { preferencesSlice } from "./slices/preferences";

export const store = configureStore({
    reducer: {
        preferences: preferencesSlice.reducer,
    },

    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware({
    //         serializableCheck: {
    //             // Ignore these action types
    //             ignoredActions: ['websiteData/changeWebsiteData'],
    //             // Ignore these field paths in all actions
    //             ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
    //             // Ignore these paths in the state
    //             ignoredPaths: ['websiteData.value'],
    //         },
    //     }),
});

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
