import * as gqlb from "gql-query-builder";
const listDeliveryAgentField = [
  "id",
  "name",
  "pickupCommission",
  "code",
  "reviewAvg",
  "reviewCount",
  "mobile",
  "phone",
  "active",
  "autoPayCommission",
  {
    branch: ["id", "name"],
  },
  {
    route: ["id", "name"],
  },
];

export const LIST_DELIVERY_AGENTS = gqlb.query({
  operation: "listDeliveryAgents",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [...listDeliveryAgentField],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListDeliveryAgentsFilterInput",
    },
  },
});
export const LIST_DELIVERY_AGENTS_DUE_INQUIRY = gqlb.query({
  operation: "listDeliveryAgents",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "name",

        "code",

        {
          branch: ["id", "name"],
        },
        "commissionDueAmount",
        "collectionDueAmount",
      ],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListDeliveryAgentsFilterInput",
    },
  },
});
export const DELIVERY_AGENT_ID = gqlb.query({
  operation: "deliveryAgent",
  fields: [
    "id",
    "code",
    "name",
    "commission",
    "address",
    "postalCode",
    "reviewAvg",
    "reviewCount",
    "mailBox",
    "active",
    "phone",
    "mobile",
    "email",
    "autoPayCommission",
    "pickupCommission",
    {
      glAccount: ["name", "id"],
    },
    {
      commissionList: ["name", "id"],
    },
    {
      custodyAccount: ["name", "id"],
    },
    {
      country: ["name", "id"],
    },
    {
      state: ["name", "id"],
    },
    {
      city: ["name", "id"],
    },
    {
      area: ["code", "name", "id"],
    },
    {
      branch: ["name", "main", "id"],
    },
    {
      route: ["name", "id"],
    },
    {
      commissionType: ["name", "code"],
    },
    {
      users: ["id", "username"],
    },
    { integration: ["id"] },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_DELIVERY_AGENT = gqlb.mutation({
  operation: "saveDeliveryAgent",
  fields: ["id", "code", "active"],
  variables: {
    input: {
      type: " DeliveryAgentInput",
      required: true,
    },
  },
});

export const SETTINGS = gqlb.query({
  operation: "shippingSettings",
  fields: [
    { mainDeliveryAgentGLAccount: ["id", "name"] },
    { mainCustodyGLAccount: ["id", "name"] },
  ],
  variables: {},
});
