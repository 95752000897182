import { createAsyncThunk } from '@reduxjs/toolkit'
import { client } from '../../Component/HOC/withApolloProvider'
import { SAVE_PREFERENCES } from '../mutations'

export const updateDarkModeAsync = createAsyncThunk(
    'preferences/updateDarkMode',
    async (darkMode, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: SAVE_PREFERENCES,
                variables: {
                    input: {
                        ...(darkMode.id && { id: darkMode.id }),
                        key: "darkMode",
                        value: darkMode.value
                    }
                },
            })
            if (response.data.savePreferences) {
                // Update localStorage
                localStorage.setItem("darkMode", darkMode.value)
                return {
                    id: response.data.savePreferences.id,
                    value: darkMode.value
                }
            } else {
                throw new Error(response.data.savePreferences.message)
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const updateLanguageAsync = createAsyncThunk(
    'preferences/updateLanguage',
    async (language, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: SAVE_PREFERENCES,
                variables: {
                    input: {
                        ...(language.id && { id: language.id }),
                        key: "language",
                        value: language.value
                    }
                },
            })
            if (response.data.savePreferences) {
                // Update localStorage
                localStorage.setItem("i18nextLng", language.value)
                return {
                    id: response.data.savePreferences.id,
                    value: language.value
                }
            } else {
                throw new Error(response.data.savePreferences.message)
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const updateExportInvoiceAsync = createAsyncThunk(
    'preferences/exportInvoice',
    async (exportInvoice, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: SAVE_PREFERENCES,
                variables: {
                    input: {
                        ...(exportInvoice.id && { id: exportInvoice.id }),
                        key: "exportInvoice",
                        value: JSON.stringify(exportInvoice.value)
                    }
                },
            })
            if (response.data.savePreferences) {
                return {
                    id: response.data.savePreferences.id,
                    value: exportInvoice.value
                }
            } else {
                throw new Error(response.data.savePreferences.message)
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const updateExportPaymentCUSTMAsync = createAsyncThunk(
    'preferences/exportPaymentCUSTM',
    async (exportPaymentCUSTM, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: SAVE_PREFERENCES,
                variables: {
                    input: {
                        ...(exportPaymentCUSTM.id && { id: exportPaymentCUSTM.id }),
                        key: "exportPaymentCUSTM",
                        value: JSON.stringify(exportPaymentCUSTM.value)
                    }
                },
            })
            if (response.data.savePreferences) {
                return {
                    id: response.data.savePreferences.id,
                    value: exportPaymentCUSTM.value
                }
            } else {
                throw new Error(response.data.savePreferences.message)
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const updateExportPaymentDLVBYAsync = createAsyncThunk(
    'preferences/exportPaymentDLVBY',
    async (exportPaymentDLVBY, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: SAVE_PREFERENCES,
                variables: {
                    input: {
                        ...(exportPaymentDLVBY.id && { id: exportPaymentDLVBY.id }),
                        key: "exportPaymentDLVBY",
                        value: JSON.stringify(exportPaymentDLVBY.value)
                    }
                },
            })
            if (response.data.savePreferences) {
                return {
                    id: response.data.savePreferences.id,
                    value: exportPaymentDLVBY.value
                }
            } else {
                throw new Error(response.data.savePreferences.message)
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const updateExportCollectionCUSTMAsync = createAsyncThunk(
    'preferences/exportCollectionCUSTM',
    async (exportCollectionCUSTM, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: SAVE_PREFERENCES,
                variables: {
                    input: {
                        ...(exportCollectionCUSTM.id && { id: exportCollectionCUSTM.id }),
                        key: "exportCollectionCUSTM",
                        value: JSON.stringify(exportCollectionCUSTM.value)
                    }
                },
            })
            if (response.data.savePreferences) {
                return {
                    id: response.data.savePreferences.id,
                    value: exportCollectionCUSTM.value
                }
            } else {
                throw new Error(response.data.savePreferences.message)
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const updateExportCollectionDLVBYAsync = createAsyncThunk(
    'preferences/exportCollectionDLVBY',
    async (exportCollectionDLVBY, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: SAVE_PREFERENCES,
                variables: {
                    input: {
                        ...(exportCollectionDLVBY.id && { id: exportCollectionDLVBY.id }),
                        key: "exportCollectionDLVBY",
                        value: JSON.stringify(exportCollectionDLVBY.value)
                    }
                },
            })
            if (response.data.savePreferences) {
                return {
                    id: response.data.savePreferences.id,
                    value: exportCollectionDLVBY.value
                }
            } else {
                throw new Error(response.data.savePreferences.message)
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)