import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { gql, useQuery } from "@apollo/client";
import { FilterList, Print, Search } from "@mui/icons-material";
import { Box, Button, TableBody, TableRow } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  LIST_BANK_DROPDOWN,
  LIST_BRANCHES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_CUSTOMERS_DEU_INQUIRY } from "./Graphql";
// import ExportCustomerList from "./ExportCustomersList";
import { GetPaymentPermissionSlug } from "../../helpers/getPermissionSlug";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
// import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";
import { Can } from "../HOC/CustomComponents/Secured";
import formatMoney from "../../helpers/numbersDot";
import PhoneNumberActions from "../HOC/CustomComponents/PhoneNumberActions";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import ExportCustomerList from "./ExportCustomersList";

const PREFIX = "CustomerDueInquiry";

const classes = {
  span: `${PREFIX}-span`,
  iconColor: `${PREFIX}-iconColor`,
  headerTable: `${PREFIX}-headerTable`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.span}`]: {
    display: "inline",
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%",
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column",
  },
}));
const CustomerDueInquiry = (props) => {
  const { t } = useTranslation();
  const urlQuery = urlParameters(window.location.search);
  const [refetch, setRefetch] = useState(true);

  const [noData, setNoData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const [page, setPage] = useState(urlQuery["page"] ?? 0);

  const user = Globals.user;
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const paymentPermission =
    user.hasPermission(GetPaymentPermissionSlug("custm", "create")) ||
    user.hasPermission(GetPaymentPermissionSlug("dlvby", "create"));

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    bank: null,
    paymentMethodCode: null,
  });

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };
  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    delete urlQuery["page"];
    if (validUrlParameters) {
      if (urlQuery["accountNumber"])
        urlQuery["accountNumber"] = urlQuery["accountNumber"].toString();
      if (urlQuery["vodafoneCash"])
        urlQuery["vodafoneCash"] = urlQuery["vodafoneCash"].toString();
      if (urlQuery["paymentTypes"])
        urlQuery["paymentTypes"] = urlQuery["paymentTypes"].split(",");
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["vodafoneCash"] &&
      setValue("vodafoneCash", urlQuery["vodafoneCash"]);
    urlQuery["accountNumber"] &&
      setValue("accountNumber", urlQuery["accountNumber"]);
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const pushUrlSearch = (param) => {
    const url = history.createHref({
      pathname: "/admin/customers/due-inquiry",
      search: "?" + searchParams(param),
    });
    windowUrl(url);
  };

  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const onPrint = () => {
    const params = searchParams(urlQuery);
    const domain = `${window.location.origin}`;
    window.open(
      `${domain}/customer-due-inquiry-list/print?PP=${paymentPermission}&${params}`
    );
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setRefetch(false) : setRefetch(true);
    let handledData = {
      paymentMethodCode: data.paymentMethodCode,
      ...(data.paymentMethodCode === "BNK" && { bankId: data.bankId }),
      ...(data.paymentMethodCode === "VCSH" && {
        vodafoneCash: data.vodafoneCash,
      }),
      ...(data.paymentMethodCode === "BNK" && {
        accountNumber: data.accountNumber,
      }),
      branchId: data.branchId,
      name: data.name,
      code: data.code,
      page: 0,
    };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
    setPage(0)
  };

  const parseData = (data) => {
    return data;
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });
    setPage(newPage);
    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setPage(0);
    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const queryBody = LIST_CUSTOMERS_DEU_INQUIRY(paymentPermission).query;
  const { data, loading } = useQuery(
    gql`
      ${queryBody}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          withDue: "PAY",
          ...(search.paymentMethodCode && {
            paymentMethodCode: search.paymentMethodCode,
          }),
          ...(search.paymentMethodCode &&
            search.bankId && { bankId: search.bankId }),
          ...(search.paymentMethodCode &&
            search.vodafoneCash && { vodafoneCash: search.vodafoneCash }),
          ...(search.paymentMethodCode &&
            search.accountNumber && { accountNumber: search.accountNumber }),
          ...(search.code && { code: search.code }),
          ...(search.name && { name: search.name }),
          ...(search.branchId && {
            branchId: { value: search.branchId, includeNull: false },
          }),
        },
      },
      onCompleted: () => {
        setNoData(false);
      },
    }
  );

  const customerList =
    data?.listCustomers?.data !== null ? data?.listCustomers?.data : null;

  const exportFilters = {
    withDue: "PAY",
    ...(search.code && { code: search.code }),
    ...(search.name && { name: search.name }),
    ...(search.branchId && {
      branchId: { value: search.branchId, includeNull: false },
    }),
    ...(search.paymentMethodCode && {
      paymentMethodCode: search.paymentMethodCode,
    }),
    ...(search.paymentMethodCode && search.bankId && { bankId: search.bankId }),
    ...(search.paymentMethodCode &&
      search.vodafoneCash && { vodafoneCash: search.vodafoneCash }),
    ...(search.paymentMethodCode &&
      search.accountNumber && { accountNumber: search.accountNumber }),
  };

  const tableHeaderArray = [
    {
      name: "storeName",
    },
    {
      name: "name",
    },
    {
      name: "branch",
    },
    {
      name: "phone",
    },
    {
      name: "paymentMethod",
    },
  ];

  const paymentMethodCode = urlQuery["paymentMethodCode"];

  const showAccountNumber = !paymentMethodCode || paymentMethodCode === "BNK" || paymentMethodCode === "VCSH"

  if (paymentPermission) {
    tableHeaderArray.splice(2, 0, {
      name: "customerDueAmount",
    });
  }

  if (!paymentMethodCode || paymentMethodCode === "BNK") {
    tableHeaderArray.splice(7, 0, {
      name: "bank",
    });
  }

  if (showAccountNumber) {
    tableHeaderArray.splice(8, 0, {
      name: "accountNumber",
    });
  }

  const [openExport, setOpenExport] = useState(false);

  const openExportDialog = () => {
    setOpenExport(true);
  };

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "printList",
      title: "printList",
      action: onPrint,
      icon: Print,
      disabled: customerList?.length === 0,
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: Boolean(customerList?.length === 0),
    },
  ];

  return (
    <Root>
      <ExportCustomerList
        filters={exportFilters}
        openExport={openExport}
        setOpenExport={setOpenExport}
        customerDue={true}
      />
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={customerList?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"branchId"}
                  label={t("branch")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <LookupDropdown
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"paymentMethodCode"}
                  label={t("paymentMethod")}
                  variables={{
                    input: { code: "SHP_PAYMENT_METHODS" },
                  }}
                  onCompleted={(data) =>
                    lookupsComplete(
                      data,
                      urlQuery["paymentMethodCode"],
                      "paymentMethodCode"
                    )
                  }
                />
              </Grid>
              {watch("paymentMethodCode") === "BNK" && (
                <Grid item sm={12} xs={12}>
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    skip={noData}
                    name={"bankId"}
                    label={t("bank")}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["bankId"],
                        "bank"
                      )
                    }
                    parseData={(data) => parseData(data)}
                    query={LIST_BANK_DROPDOWN.query}
                    defaultValue={autocompleteValues.bank}
                  />
                </Grid>
              )}
              {watch("paymentMethodCode") === "BNK" && (
                <Grid item sm={12} alignItems="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"accountNumber"}
                    label={t("accountNumber")}
                  />
                </Grid>
              )}
              {watch("paymentMethodCode") === "VCSH" && (
                <Grid item sm={12} alignItems="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"vodafoneCash"}
                    label={t("walletNumber")}
                  />
                </Grid>
              )}
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={tableHeaderArray}
        tableBody={
          <TableBody>
            {customerList &&
              customerList?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    className={classes.tableBodyRow}
                  >
                    <CellLink pathname={`/admin/customers/${row?.id}`}>
                      <Box component="span" fontWeight="bold">
                        {` (${row.code}) `}
                      </Box>
                      <Box key={index} component="span">
                        {row.name}
                      </Box>
                    </CellLink>
                    <FixedTableCell>{row.representative}</FixedTableCell>
                    <Can permission={paymentPermission} showException>
                      <FixedTableCell>
                        {formatMoney(row.paymentDueAmount)}
                      </FixedTableCell>
                    </Can>
                    {row?.branch ? (
                      <CellLink pathname={`/admin/branches/${row?.branch?.id}`}>
                        {row?.branch?.name}
                      </CellLink>
                    ) : (
                      <FixedTableCell> </FixedTableCell>
                    )}
                    <FixedTableCell>
                      <Box>
                        {" "}
                        <PhoneNumberActions phone={row.phone} />{" "}
                      </Box>
                      <Box>
                        {" "}
                        <PhoneNumberActions mobile={row.mobile} />
                      </Box>
                    </FixedTableCell>
                    <FixedTableCell>{row?.paymentMethod?.name}</FixedTableCell>

                    {(!paymentMethodCode || paymentMethodCode === "BNK") && (
                      <FixedTableCell>{row?.bank?.name}</FixedTableCell>
                    )}
                    {showAccountNumber && <FixedTableCell>
                      {row?.paymentMethod?.code === "VCSH"
                        ? row?.vodafoneCash
                        : row?.accountNumber}
                    </FixedTableCell>}
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listCustomers?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listCustomers ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default CustomerDueInquiry;
