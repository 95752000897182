import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  Stack,
  Collapse,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import { LIST_SHIPPING_SERVICES, SAVE_INTEGRATION_SERVICE } from "./Graphql";
import { useQuery, gql, useMutation } from "@apollo/client";
import MUIselect from "../../HOC/MUI/MUIselect";
import { useForm } from "react-hook-form";
import FullScreenLoading from "../../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useSnackbar } from "notistack";

const PREFIX = "ServiceTable";

const classes = {
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
};

const StyledGrid = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(2),
  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },
}));

const ServiceTable = (props) => {
  const { servicesDropdown = [],
    title,
    integrationId,
    servicesQueryData,
    setServicesQueryData,
    servicesRemoteIds,
    setServicesRemoteIds
  } = props;
  const { control, setValue } = useForm();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const [saveIntegrationServices] = useMutation(
    gql`
      ${SAVE_INTEGRATION_SERVICE.query}
    `
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setServicesQueryData(null)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setServicesQueryData(null)
  };

  const { loading: servicesLoading } = useQuery(
    gql`
      ${LIST_SHIPPING_SERVICES.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !!servicesQueryData,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          active: true
        }
      },
      onCompleted: (data) => {
        setServicesQueryData(data)
      },
    }
  );

  useEffect(() => {
    if (servicesDropdown.length) {
      servicesRemoteIds.length && servicesRemoteIds.forEach(e => {
        setValue(`remoteId-${e.localId}`, e.remoteId)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicesDropdown]);

  const data = servicesQueryData?.listShippingServices?.data

  const handleChange = (e, localId) => {
    saveIntegrationServices({
      variables: {
        input: {
          integrationId: integrationId,
          localId: localId,
          remoteId: parseInt(e.target.value)
        }
      }
    }).then((data) => {
      if (data) {
        setServicesRemoteIds(prev => {
          const loacalItem = prev.find(e => e.localId === localId)
          const newData = prev.filter(e => e.localId !== localId)
          if (loacalItem) {
            loacalItem.remoteId = parseInt(e.target.value)
            newData.push(loacalItem)
          } else {
            newData.push({
              remoteId: parseInt(e.target.value),
              localId: localId
            })
          }
          return newData
        })
      }
    }).catch(({ graphQLErrors }) => {
      enqueueSnackbar(graphQLErrors[0].message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
    })
  }

  return (
    <StyledGrid alignItems="flex-start" component={Paper}>
      {servicesLoading && <FullScreenLoading minHeight={"200px"} />}
      {!servicesLoading && <Grid container item className={classes.table}>
        {data?.length <= 0 ? (
          <EmptyTableMessage loading={false} message={t(`${title}`)} />
        ) : (
          <TableFixedHeaderWraper className={classes.tableContainer}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("local")}</FixedTableCell>
                  <FixedTableCell>{t("otherCompany")}</FixedTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.map((row, index) => (
                  <TableRow hover key={index}>
                    <FixedTableCell width={"200px"}>
                      {row?.name}
                    </FixedTableCell>
                    <FixedTableCell sx={{ padding: "4px" }}>
                      <MUIselect
                        width={"200px"}
                        variant={"outlined"}
                        data={servicesDropdown?.map(item => {
                          return {
                            key: item.name,
                            value: item.id
                          }
                        })}
                        name={`remoteId-${row?.id}`}
                        label=""
                        control={control}
                        onChanges={(e) => handleChange(e, row?.id)}
                      />
                    </FixedTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
        )}
        <MUITablePagination
          count={servicesQueryData?.listShippingServices?.paginatorInfo?.total}
          rowsPerPage={rowsPerPage}
          page={!servicesQueryData?.listShippingServices ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>}
    </StyledGrid>

  );
};

export default ServiceTable;
