import React, { Fragment, useEffect } from "react";
// import "./ShipmentPrint.css";
import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { withRouter } from "react-router";
import clsx from "clsx";

import { urlParameters } from "../../HOC/CustomFunctions/urlParameters";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { MdOutlineDone } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import LogoImg from "../../../Layout/LogoImg";

// const SHIPMENT_QUERY = gqlb.query([
//   {
//     operation: "listShipments",
//     fields: [
//       {
//         data:,
//     variables: {
//       input: {
//         type: "ListShipmentsFilterInput",
//       },
//     },
//   },
// ]);

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
  flex: `${PREFIX}-flex`,
  printSticky: `${PREFIX}-printSticky`,
  stickyCode: `${PREFIX}-stickyCode`,
  centerContent: `${PREFIX}-centerContent`,
  barcode: `${PREFIX}-barcode`,
  line: `${PREFIX}-line`,
  lineDots: `${PREFIX}-lineDots`,
  tableData: `${PREFIX}-tableData`,
  width50: `${PREFIX}-width50`,
  borderRight: `${PREFIX}-borderRight`,
  padding3: `${PREFIX}-padding3`,
  padding1: `${PREFIX}-padding1`,
  hideSenderPhone: `${PREFIX}-hideSenderPhone`,
  notes: `${PREFIX}-notes`,
  recipient: `${PREFIX}-recipient`,
  recipientAddress: `${PREFIX}-recipientAddress`,
  recipientHeight: `${PREFIX}-recipientHeight`,
  senderHeight: `${PREFIX}-senderHeight`,

};
export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.hideSenderPhone}`]: {
    display: "none !important",
  },
  [`& .${classes.printSticky}`]: {
     
    pageBreakInside: "avoid",
    maxWidth: "7cm",
    maxHeight: "9cm",
    overflow: "hidden",
    fontSize: 11,
    color: "black",
  },
  [`& .${classes.line}`]: {
    borderBottom: "1px solid #0000002b",
  },
  [`& .${classes.lineDots}`]: {
    borderBottom: "1px dotted #0000002b",
  },
  [`& .${classes.borderRight}`]: {
    borderRight: "1px solid #0000002b",
  },
  [`& .${classes.flex}`]: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
  },
  [`& .${classes.width50}`]: {
    width: "50%"
  },
  [`& .${classes.stickyCode}`]: {
    height: "calc(100% * (1/4))",
    overflow: "hidden",
    // fontSize: "30px !important",
  },
  [`& .${classes.tableData}`]: {
    width: "calc(100% * (1/4))",
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    textAlign: "center",
    padding: "2px 0",
    height: "31px",
    justifyContent: 'space-between'
  },
  [`& .${classes.padding3}`]: {
    padding: "3px 0"
  },
  [`& .${classes.padding1}`]: {
    padding: "1px 0"
  },

  [`& .${classes.stickyDestination}`]: {
    height: "calc(100% * (0.75/4))",
    overflow: "hidden",
    whiteSpace: "pre",
  },

  [`& .${classes.centerContent}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 44,
  },
  [`& .${classes.notes}`]: {
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box !important",
    paddingBottom: "2px",
    lineHeight: 1,
    minHeight: "25px",
  },
  [`& .${classes.recipient}`]: {
    maxHeight: "90px",
    overflow: "hidden",
  },
  [`& .${classes.recipientAddress}`]: {
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box !important"
  },
  [`& .${classes.recipientHeight}`]: {
    minHeight: "80px",
  },
  [`& .${classes.senderHeight}`]: {
    minHeight: "45px",
  },
}));
const organizationQuery = {
  operation: "organization",
  fields: ["reportHeader"],
  variables: {},
};
const listShipmentsFields = [
  {
    data: [
      "code",
      "recipientName",
      "recipientAddress",
      "recipientMobile",
      "recipientPhone",
      "description",
      "weight",
      "piecesCount",
      "totalAmount",
      "senderName",
      "senderPhone",
      "senderMobile",
      "refNumber",
      "notes",
      "date",
      {
        type: ["name", "code"],
      },
      {
        originBranch: ["name"],
      },
      {
        customer: [
          "code",
          {
            operation: `isMobileHidden(code: WAYBILL)`,
            fields: [],
            variables: {
            },
          },
        ],
      },
      {
        openable: ["code"],
      },

      {
        recipientZone: ["name"],
      },
      {
        recipientSubzone: ["name"],
      },
      {
        senderZone: ["name"],
      },
      {
        senderSubzone: ["name"],
      },
    ],
  },
];
const barcodeBatch = [
  "id",
  { barcodes: ["id", "code"] },
  {
    customer: [
      "code",
      {
        operation: `isMobileHidden(code: WAYBILL)`,
        fields: [],
        variables: {
        },
      },],
  },
];
const queryBuilder = (ids) => {
  const queryNumber = Math.ceil(ids.length / 100);
  const queryArray = [organizationQuery];
  for (let index = 0; index < queryNumber; index++) {
    const id = ids.slice(100 * index, 100 * (index + 1));
    const initialQuery = {
      operation: `list${index}:listShipments(first:100,input:{id:[${id}]})`,
      fields: listShipmentsFields,
      variables: {},
    };
    queryArray.push(initialQuery);
  }
  return queryArray;
};
const queryBuilderCode = (ids) => {
  const queryArray = [organizationQuery];
  const initialQuery = {
    operation: `listCode:barcodeBatch(id:${ids[0]})`,
    fields: barcodeBatch,
    variables: {},
  };
  queryArray.push(initialQuery);
  return queryArray;
};
export const SHIPPING_SETTING = gqlb.query({
  operation: "shippingSettings",
  fields: [{ localCurrency: ["name"] }],
  variables: {},
});

const ShipmentStickyPrint = (props) => {
  const id = props.match.params.id.split(",").map(Number);
  const isBatch = props.location.pathname.startsWith('/batch');
  const urlQuery = urlParameters(window.location.search);

  const { t, i18n } = useTranslation();

  const queryBody = isBatch ? queryBuilderCode(id) : queryBuilder(id);
  // const [hideSenderPhone, setHideSenderPhone] = useState(true);

  const SHIPMENT_QUERY = gqlb.query(queryBody);

  const token = urlQuery["token"];
  const lang = urlQuery["lang"];

  const { data: settings, loading: settingsLoad } = useQuery(
    gql`
      ${SHIPPING_SETTING.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      ...(token && {
        context: {
          headers: {
            authorization: `Bearer ${token}`,
            ...(lang && { "Content-Language": lang }),
          },
        },
      }),
    }
  );

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
    // setHideSenderPhone(true);
    return () => { };
  }, [i18n, lang, settings]);

  const { data, loading } = useQuery(
    gql`
      ${SHIPMENT_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !id,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        input: {
          id,
        },
      },
      ...(token && {
        context: {
          headers: {
            authorization: `Bearer ${token}`,
            ...(lang && { "Content-Language": lang }),
          },
        },
      }),
      onCompleted: () => {
        document.fonts.onloadingdone = function (fontFaceSetEvent) {
          window.print();
          document.fonts.onloadingdone = null;
        };
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const currency = ` ${settings?.shippingSettings?.localCurrency?.name} ` ?? "";
  const reportHeader = data?.organization?.reportHeader?.split("\n")
  const footer = reportHeader?.toString().replace(/,/g, " | ");

  let parsedData = [];
  if (data?.list0) {
    let concatData = [];
    for (const key in data) {
      if (key.startsWith("list")) {
        const listData = data[key].data;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }
  if (data?.listCode) {
    let concatData = [];
    for (const key in data) {
      if (key.startsWith("list")) {
        const listData = data[key].barcodes;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }
  const validData = parsedData.length > 0;
  const logoImg = <LogoImg forceLight={true} className={classes.logoImg} />
  // <img src={logo} alt="logo" className={classes.logoImg} />;

  return (
    <Fragment>
      <link
        rel="stylesheet"
        type="text/css"
        href={process.env.PUBLIC_URL + "/print-sticky7X9.css"}
      />
      {loading || (!loading && !validData) || settingsLoad ? (
        <>
          <EmptyTableMessage
            loading={loading}
            message={t("noShipmentWithCode")}
          />

          {/* <FullScreenLoading minHeight="25%" /> */}
        </>
      ) : (
        parsedData.map((shipment, index) => {
          const rtsShipment = shipment?.type?.code === "RTS";
          return (
            <Root key={index}>
              <div
                className={clsx({
                  [classes.printSticky]: !loading && validData,
                })}
                style={{ height: "100%", lineHeight: "1.2" }}
              >
                <div className={clsx(
                  classes.flex,
                  classes.line
                )}>
                  <div className={clsx(classes.width50)}>{logoImg}</div>
                  <div className={clsx(classes.width50, classes.flex)}>
                    <div className={clsx(classes.padding3)} style={{
                      width: "auto",
                    }}>{shipment?.type?.name}</div>
                  </div>
                </div>
                <div
                  className={clsx(
                    classes.barcode,
                    classes.centerContent,
                    classes.stickyCode,
                    classes.line
                  )}
                >
                  *{shipment.code}*
                </div>
                <div className={clsx(
                  classes.flex,
                  classes.line,
                )}>
                  <div className={clsx(classes.tableData, classes.borderRight)}>
                    <Box component="span" >
                      {t("weight")}
                    </Box>
                    <div>{shipment?.weight}</div>
                  </div>
                  <div className={clsx(classes.tableData, classes.borderRight)}>
                    <Box component="span" >
                      {t("collection")}
                    </Box>
                    <div>
                      <Box component="span">
                        {rtsShipment ? 0 : shipment?.totalAmount}
                      </Box>
                      <Box component="span" pl="2px">
                        {!isBatch && currency}
                      </Box>
                    </div>
                  </div>
                  <div className={clsx(classes.tableData, classes.borderRight)}>
                    <Box component="span">
                      {t("open")}
                    </Box>
                    <div>
                      {!isBatch && (shipment?.openable?.code === "Y" ? (<MdOutlineDone />) : (<AiOutlineClose />))}
                      {/* <CheckCrossIcons
                        active={shipment?.openable?.code === "Y"}
                      /> */}
                    </div>
                  </div>
                  <div className={clsx(classes.tableData)}>
                    <Box component="span" >
                      {t("piece")}
                    </Box>
                    <div>{shipment?.piecesCount}</div>
                  </div>
                </div>
                <div className={clsx(
                  classes.line,
                  classes.padding3,
                  classes.recipient,
                  isBatch && classes.recipientHeight
                )}>
                  <div><span style={{ fontWeight: "bolder" }}>{t("recipient") + ": "}</span>{shipment?.recipientName}</div>
                  <div
                    className={clsx(classes.padding1)}
                  >
                    {isBatch ? ' ' : (shipment?.recipientMobile &&
                      shipment?.recipientPhone
                      ? `${shipment?.recipientMobile} - ${shipment?.recipientPhone}`
                      : shipment?.recipientMobile ??
                      shipment?.recipientPhone)}
                  </div>
                  <div
                    className={clsx(classes.padding1, classes.recipientAddress)}
                  >
                    {shipment?.recipientAddress}
                  </div>
                  <div
                    className={clsx(classes.padding1
                    )}
                  >
                    {!isBatch && (shipment?.recipientZone?.name +
                      " - " +
                      shipment?.recipientSubzone?.name)}
                  </div>
                </div>
                <div className={clsx(
                  classes.line,
                  classes.padding3,
                  isBatch && classes.senderHeight
                )}>
                  <div><span style={{ fontWeight: "bolder" }}>{t("sender") + ": "}</span> {shipment?.senderName}</div>
                  <div
                    className={clsx(classes.padding1, {
                      [classes.hideSenderPhone]: shipment?.customer?.isMobileHidden,
                    }
                    )}
                  >
                    {shipment?.senderMobile && shipment?.senderPhone
                      ? `${shipment?.senderMobile} - ${shipment?.senderPhone}`
                      : shipment?.senderMobile ??
                      shipment?.senderPhone}
                  </div>
                  <div
                    className={clsx(classes.padding1
                    )}
                  >
                    {!isBatch && (shipment?.senderZone?.name +
                      " - " +
                      shipment?.senderSubzone?.name)}
                  </div>
                </div>

                <div className={clsx(
                  classes.lineDots,
                  classes.padding3
                )}>
                  <div><span style={{ fontWeight: "bolder" }}>{t("refNumber") + ": "}</span>{shipment?.refNumber}</div>
                </div>
                <div className={clsx(
                  classes.line,
                  classes.notes,
                )}>
                  <Box component="span" pt="1px">
                    <span style={{ fontWeight: "bolder" }}>{t("notes") + ": "}</span>{shipment?.notes}
                  </Box>
                </div>
                <Box component="span"
                  sx={{ display: "flex", justifyContent: 'center' }}>
                  <Box component="span" pl="2px">
                    {footer}
                  </Box>
                </Box>
              </div>
            </Root>
          )
        })
      )}
    </Fragment>
  );
};

export default withRouter(ShipmentStickyPrint);
