/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  Paper,
} from "@mui/material";
import { DeleteOutline, Edit, Print } from "@mui/icons-material";
import { BARCODEBATCH, DELETE_BARCODEBATCH_MUTATION } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import Grid from "@mui/material/Unstable_Grid2";
import styled from "@emotion/styled";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import PrintComponent from "../Shipments/PrintComponent";
import TitleAppBar from "../../Layout/TitleAppBar";
import { printLink } from "../../helpers/defaultPrintList";
import { Globals } from "../HOC/Classes/Globals";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";

const PREFIX = "GenrateBarcodesView";

const classes = {
  paper: `${PREFIX}-paper`,
  main: `${PREFIX}-main`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const GenrateBarcodesView = (props) => {
  const GenerateBarCodesId = props.id ?? props.match?.params?.id?.trim();
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${BARCODEBATCH.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(GenerateBarCodesId) },
    }
  );
  const barcodeBatch = data?.barcodeBatch
  const barcodes = data?.barcodeBatch?.barcodes
    ?.map((i) => i.code)
    .toString()
    .replace(/,/g, " , ");

  const defaultPrint = Globals.settings.waybillCode;
  const [openPrint, setOpenPrint] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const openDeleteDialog = () => {
    setOpenDelete(true)
  };
  const openPrintDialog = () => {
    defaultPrint ?
      printLink(defaultPrint, true, GenerateBarCodesId) :
      setOpenPrint(true)
  };

  const editURL = () => {
    pushUrl(props, `/admin/barcode-batches/${GenerateBarCodesId}/edit`);
  }

  const icons = [
    {
      id: "print",
      title: "print",
      action: openPrintDialog,
      icon: Print,
      permission: !Boolean(barcodeBatch?.editable),
    },
    {
      id: "delete",
      title: "delete",
      action: openDeleteDialog,
      icon: DeleteOutline,
      permission: Boolean(barcodeBatch?.deletable),
    },
    {
      id: "edit",
      title: "edit",
      action: editURL,
      icon: Edit,
      permission: Boolean(barcodeBatch?.editable),
    },
  ]

  return (
    loading ?
      <StyledLoading container item justifyContent="center" className={classes.main}>
        <FullScreenLoading height={"100%"} />
      </StyledLoading >
      : !barcodeBatch ? (
        <NotFound />
      ) : (
        <StyledGrid
          container
          justifyContent="center"
          sx={{ margin: "0", width: "100%" }}
        >
          <PrintComponent shipmentsId={GenerateBarCodesId} batch='batch' openPrint={openPrint} setOpenPrint={setOpenPrint} />
          <MutationWithDialog
            mutaion={DELETE_BARCODEBATCH_MUTATION.query}
            setOpenDelete={setOpenDelete}
            openDelete={openDelete}
            dialogTitle={t("deleteBarcode")}
            dialogContent={t("deleteBarcodeMessage")}
            mutaionProps={{ variables: { id: parseInt(GenerateBarCodesId) } }}
            onCompleted={() => pushUrl(props, `/admin/barcode-batches`)}
            onCompleteMessage={t("deleteMessage")}
          />
          <TitleAppBar path={props.match.path}>
            <LongMenu icons={icons} />
          </TitleAppBar>
          <Grid
            container
            justifyContent="center"
            sx={{ width: "100%", p: 2, gap: 2 }}
          >
            <Paper container xs={12} className={classes.paper} component={Grid}>
              <KeyValuePair
                title={t("branch")}
                value={
                  barcodeBatch?.branch ? (
                    <SpanLink
                      pathname={`/admin/branches/${barcodeBatch?.branch?.id}`}
                    >
                      {barcodeBatch?.branch?.name}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
              <KeyValuePair
                title={t("transactionTypeName")}
                value={
                  barcodeBatch?.transactionType ? (
                    <SpanLink
                      pathname={`/admin/transaction-types/${barcodeBatch?.transactionType?.id}`}
                    >
                      {barcodeBatch?.transactionType?.name}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
              <KeyValuePair
                title={t("customer")}
                value={
                  barcodeBatch?.customer ? (
                    <SpanLink
                      pathname={`/admin/customers/${barcodeBatch?.customer?.id}`}
                    >
                      {barcodeBatch?.customer?.name}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
              <KeyValuePair
                title={t("createdBy")}
                value={
                  barcodeBatch?.createdBy ? (
                    <SpanLink
                      pathname={`/admin/users/${barcodeBatch?.createdBy?.id}`}
                    >
                      {barcodeBatch?.createdBy?.username}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
              <KeyValuePair
                title={t("updatedBy")}
                value={
                  barcodeBatch?.updatedBy ? (
                    <SpanLink
                      pathname={`/admin/users/${barcodeBatch?.updatedBy?.id}`}
                    >
                      {barcodeBatch?.updatedBy?.username}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
              <KeyValuePair
                title={t("barcode")}
                value={barcodes}
              />
            </Paper>
          </Grid>
        </StyledGrid >
      )
  );
};

export default GenrateBarcodesView;
