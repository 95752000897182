import React, { useState } from 'react'
import { styled } from "@mui/material/styles";
import { Box, Collapse, Paper, Stack, Typography } from '@mui/material';
import TitleAppBar from '../../../Layout/TitleAppBar';
import Grid from '@mui/material/Unstable_Grid2';
import MuiSwitch from '../../HOC/MUI/MUIswitch';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ControlMUItextField from '../../HOC/MUI/ControlMUItextField';
import EnumDropdown from '../../HOC/CustomComponents/EnumDropdown';
import ButtonLoading from '../../HOC/FunctionComponents/LoadingPages/ButtonLoading';
import FormButton from '../../CustomComponents/Buttons/FormButton';
import { CANCELLATIONREASONS_DROPDOWN_QUERY, CUSTOMER_ID, ME, SAVE_INTEGRATION } from './Graphql';
import { useMutation, gql, useQuery } from "@apollo/client";
import { enumNameIsValid } from '../../HOC/CustomFunctions/lookupCodeIsValid';
import { useSnackbar } from 'notistack';
import FullScreenLoading from '../../HOC/FunctionComponents/LoadingPages/FullScreenLoading';
import { setValidationError } from '../../HOC/CustomFunctions/setValidationError';
import CancellationReasonsTable from './CancellationReasonsTable';
import CustomTab from '../../HOC/FunctionComponents/CustomTab';

const PREFIX = "CustomerIntegrationForm";

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  main: `${PREFIX}-main`,
  title: `${PREFIX}-title`,
  disableForm: `${PREFIX}-disableForm`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.spacing}`]: {
    position: "relative",
    width: "100%",
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(3),
  },
  [`& .${classes.disableForm}`]: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },
  [`& .MuiTabScrollButton-root`]: {
    display: "none"
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const CustomerIntegrationForm = (props) => {
  const { t } = useTranslation(["translation", "validation"]);
  const customerId = parseInt(props.match.params.id);
  const [checkValidationLoading, setCheckValidationLoading] = useState(false);
  const [typeCodeCheck, setTypeCodeCheck] = useState(false);
  const [cancellationReasonsDropdown, setCancellationReasonsDropdown] = useState([]);

  const [cancellationReasonsRemoteIds, setCancellationReasonsRemoteIds] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [cancellationReasonsQueryData, setCancellationReasonsQueryData] = useState(null);

  const { handleSubmit, control, formState: { errors }, setValue, setError, watch } = useForm({
    defaultValues: {
      active: true,
    },
  })

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && enumNameIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const fetchCancellationReasonsDropdowns = async (token, url) => {
    if (!token || !url) {
      return;
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: CANCELLATIONREASONS_DROPDOWN_QUERY,
          variables: {
            input: { type: "DEX" },
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.errors) {
        console.error('GraphQL errors:', data.errors);
        return null;
      }

      // Return both zones and services data
      return data;
    } catch (error) {
      console.error('Error fetching combined dropdowns:', error);
      return null;
    }
  };


  const { data: customerData, loading: customerLoading } =
    useQuery(
      gql`
        ${CUSTOMER_ID.query}
      `,
      {
        skip: !customerId,
        variables: { id: customerId },
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
          const integrationData = data.customer.integration;

          if (!!integrationData?.typeCode) {
            setTypeCodeCheck(true)
            setValue("token", integrationData?.token);
            const servicesIds = integrationData.cancellationReasons.map(e => {
              return {
                remoteId: e.remoteId,
                localId: e.localCancellationReason.id
              }
            })
            setCancellationReasonsRemoteIds(servicesIds)
            fetchCancellationReasonsDropdowns(integrationData?.token, integrationData?.integrationUrl)
              .then((data) => {
                setCancellationReasonsDropdown(data.data.listCancellationReasonsDropdown ?? []);
              })
              .catch((error) => {
                console.error(error);
              });
          }

          setValue("active", integrationData.active);
          setValue("integrationUrl", integrationData?.integrationUrl);
          setValue("id", integrationData?.id);
        },
      }
    );

  const [saveIntegration, { loading: saveIntegrationLoading }] = useMutation(
    gql`
      ${SAVE_INTEGRATION.query}
    `
  );

  const checkVailedUrl = async (url, token) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: ME,
          variables: {},
        }),
      });

      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.errors) {
        console.error('GraphQL errors:', data.errors);
        return {
          status: false,
          field: "token",
          message: "notValid"
        };
      }
      // Return both zones and services data
      return {
        status: true,
      };
    } catch (error) {
      console.error('Error fetching combined dropdowns:', error);
      return {
        status: false,
        field: "integrationUrl",
        message: "notValid"
      };
    }
  };

  const onSubmit = (data) => {
    if (data.typeCode) {
      setCheckValidationLoading(true)
      checkVailedUrl(data.integrationUrl, data.token)
        .then((checkValidData) => {
          if (checkValidData.status) {
            saveIntegration({
              variables: {
                input: {
                  ...data,
                  customerId: customerId,
                },
              },
            })
              .then((data) => {
                setTypeCodeCheck(true)
                setCheckValidationLoading(false)
                setValue("id", data.data.saveIntegration?.id);
                const servicesIds = data.data.saveIntegration.cancellationReasons.map(e => {
                  return {
                    remoteId: e.remoteId,
                    localId: e.localCancellationReason.id
                  }
                })
                setCancellationReasonsRemoteIds(servicesIds)

                fetchCancellationReasonsDropdowns(data.data.saveIntegration?.token, data.data.saveIntegration?.integrationUrl)
                  .then((data) => {
                    setCancellationReasonsDropdown(data.data.listCancellationReasonsDropdown ?? []);
                  })
                  .catch((error) => {
                    console.error(error);
                  });


                enqueueSnackbar(t("saveSuccessful"), {
                  variant: "success",
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  TransitionComponent: Collapse,
                });
              })
              .catch(({ graphQLErrors }) => {
                setCheckValidationLoading(false)
                setValidationError(graphQLErrors, setError);
              });
          } else {
            setCheckValidationLoading(false)
            setError(checkValidData.field, {
              type: "validate",
              message: t(`validation:${checkValidData.message}`)
            })
          }
        })
    } else {
      saveIntegration({
        variables: {
          input: {
            ...(data.id && { id: data.id }),
            customerId: customerId,
            integrationUrl: data.integrationUrl,
            active: data.active,
            typeCode: null
          },
        },
      })
        .then(() => {
          setTypeCodeCheck(false)
          setCheckValidationLoading(false)
          enqueueSnackbar(t("saveSuccessful"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        })
        .catch(({ graphQLErrors }) => {
          setValidationError(graphQLErrors, setError);
        });
    }
  }

  const tapsArray = [

    {
      tabHead: <Typography>{t("cancellationReasons")}</Typography>,
      panel: (
        <CancellationReasonsTable
          cancellationReasonsQueryData={cancellationReasonsQueryData}
          setCancellationReasonsQueryData={setCancellationReasonsQueryData}
          cancellationReasonsDropdown={cancellationReasonsDropdown}
          cancellationReasonsRemoteIds={cancellationReasonsRemoteIds}
          setCancellationReasonsRemoteIds={setCancellationReasonsRemoteIds}
          title={"cancellationReasons"}
          integrationId={watch("id")}
        />
      ),
    },
  ];

  if (customerLoading) {
    return <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  }

  return (
    <Root>
      <TitleAppBar
        path={props.match.path}
        type={"merchant"}
        viewDetails={{ name: customerData?.customer?.name, id: customerId }}
      />
      <Stack spacing={2} p={2}>
        <Paper className={classes.spacing}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} m={0}>
              <Grid display={"flex"} justifyContent="end" xs={12}>
                <Box>
                  <MuiSwitch
                    edge="end"
                    name="active"
                    label={t("active")}
                    control={control}
                  />
                </Box>
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <EnumDropdown
                  name={"typeCode"}
                  label={t("type")}
                  control={control}
                  errors={errors}
                  variables={{ name: "IntegrationAdapterTypeCode" }}
                  skip={customerLoading}
                  onCompleted={(data) =>
                    lookupsComplete(
                      data,
                      customerData?.customer?.integration?.typeCode,
                      "typeCode"
                    )
                  }
                />
              </Grid>
              {watch("typeCode") && <Grid xs={12} sm={6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"token"}
                  label={t("token")}
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>}

              <Grid xs={12} sm={watch("typeCode") ? 12 : 6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  rules={{ required: t("fieldIsRequired") }}

                  name={"integrationUrl"}
                  label={t("integrationUrl")}
                />
              </Grid>

              <Grid xs={12} display={"flex"} justifyContent={"flex-end"}>
                <FormButton disabled={saveIntegrationLoading || checkValidationLoading}>
                  {(saveIntegrationLoading || checkValidationLoading) ? <ButtonLoading /> : t("save")}
                </FormButton>
              </Grid>
            </Grid>
          </form>
        </Paper>

        {typeCodeCheck &&
          <CustomTab tapDetails={tapsArray} />
        }

      </Stack>
    </Root>
  )
}

export default CustomerIntegrationForm
