import React from 'react'
import CustomDialog from '../HOC/CustomComponents/CustomDialog'
import { Button, Collapse, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ButtonLoading from '../HOC/FunctionComponents/LoadingPages/ButtonLoading'
import * as gqlb from "gql-query-builder";
import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

export const FLUSH_CACHE = gqlb.mutation({
    operation: "flushCache",
    fields: [],
    variables: {},
});



const ClearCacheDialog = ({ open, onClose, }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar();

    const [createShipmentMessageMutation, { loading }] = useMutation(
        gql`
          ${FLUSH_CACHE.query}
        `,
        {
            fetchPolicy: "no-cache",
            onCompleted: () => {
                enqueueSnackbar(t("operationSuccessful"), {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    TransitionComponent: Collapse,
                });
                onClose()
            },
            onError: (error) => {
                enqueueSnackbar(t("validationError"), {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    TransitionComponent: Collapse,
                });
                onClose()
            },
        }
    );

    return (
        <CustomDialog
            title={t("clearCache")}
            fullWidth
            maxWidth="xs"
            onClose={onClose}
            open={open}
            actions={
                <Stack direction={"row"} spacing={1}>
                    <Button color="primary" onClick={onClose}>
                        {t("close")}
                    </Button>
                    <Button
                        color="primary"
                        disabled={loading}
                        onClick={() => createShipmentMessageMutation()}
                    >
                        {loading ? (
                            <ButtonLoading />
                        ) : (
                            t("confirm")
                        )}
                    </Button>
                </Stack>

            }
        />
    )
}

export default ClearCacheDialog