export class SettingsData {
  constructor(settingsData) {
    this.clientMinimumVersion = settingsData.clientMinimumVersion;
    this.agentMinimumVersion = settingsData.agentMinimumVersion;
    this.mainCustomerGLAccount = settingsData.mainCustomerGLAccount;
    this.mainDeliveryAgentGLAccount = settingsData.mainDeliveryAgentGLAccount;
    this.mainCustodyGLAccount = settingsData.mainCustodyGLAccount;
    this.defaultShippingService = settingsData.defaultShippingService;
    this.warehousing = settingsData.warehousing;
    this.renewalDate = settingsData.renewalDate;
    this.support = settingsData.support;
    this.waybillCode = settingsData.waybillCode;
    this.multiCountries = settingsData.multiCountries;
    this.allowPhoneKey = settingsData.allowPhoneKey;
    this.otdAcceptance = settingsData.otdAcceptance;
    this.agentReview = settingsData.agentReview;
    this.countryCode = settingsData.countryCode;
    this.allowedCustomerTypes = settingsData.allowedCustomerTypes;
    this.eInvoicing = settingsData.eInvoicing;
    this.pickupCommission = settingsData.pickupCommission;
    this.separatedBranches = settingsData.separatedBranches;
    this.customReturnStatus = settingsData.customReturnStatus;
    this.customReturnStatus = settingsData.customReturnStatus;
    this.localCurrency = settingsData.localCurrency;
  }

  static shared;
}
