/* eslint-disable no-useless-concat */
import React, { useCallback, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Grid,
  Typography,
  Table,
  TableBody,
  Toolbar,
  IconButton,
  CircularProgress,
  SwipeableDrawer,
  useTheme,
  Collapse,
} from "@mui/material";
import useWidth, { isWidthDown } from "../../../Hooks/useWidth";

import { Cancel, CheckCircle, FilterList } from "@mui/icons-material";
import { EnhancedTableHead } from "./PickupListDetails";
import clsx from "clsx";
import PropTypes from "prop-types";
import { gql, useMutation, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";
import { useTranslation } from "react-i18next";
import CustomTableRow from "./CustomTableRow";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import PickupFiltersForm from "../../../Hooks/pickupListFilter";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import { SAVE_PAYMENT_ENTRIES_MUTATION } from "../PaymentListQuary";
import { useSnackbar } from "notistack";
import Backdrop from '@mui/material/Backdrop';
import PaymentPickupsProvider from "./PaymentPickupsProvider";

const TOOLBAR_PREFIX = "styledToolbar";

const toolbarClasses = {
  highlight: `${TOOLBAR_PREFIX}-highlight`,
  title: `${TOOLBAR_PREFIX}-title`,
  filterButton: `${TOOLBAR_PREFIX}-filterButton`,
};

const StyledToolbar = styled("div")(({ theme }) => ({
  width: "100%",
  zIndex: 1,

  [`& .${toolbarClasses.highlight}`]:
    theme.palette.mode === "light"
      ? {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[300],
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[800],
      },

  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 100%",
  },

  [`& .${toolbarClasses.filterButton}`]: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const PREFIX = "PickupsList";

const classes = {
  track: `${PREFIX}-track`,
  paper: `${PREFIX}-paper`,
  filters: `${PREFIX}-filters`,
};

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  width: "100%",
  backgroundColor: theme.palette.background.paper,

  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.paper}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflowX: "auto",
    maxHeight: `calc(100vh - ${theme.mixins.toolbar["minHeight"]}px)`,
  },

  [`& .${classes.filters}`]: {
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 72px)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },

    height: "100%",
    overflow: "hidden",
  },
}));

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { numSelected, done, clear, disabled, toggleFilterDrawer } = props;
  return (
    <StyledToolbar>
      <Toolbar
        className={clsx({
          [toolbarClasses.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={toolbarClasses.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t("pickupCount", { count: numSelected })}
          </Typography>
        ) : (
          <Typography
            className={toolbarClasses.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("pickups")}
          </Typography>
        )}
        {/* <Tooltip title="تم" > */}
        <IconButton
          onClick={toggleFilterDrawer}
          className={toolbarClasses.filterButton}
          size="large"
        >
          <FilterList />
        </IconButton>

        <IconButton
          aria-label="done"
          disabled={disabled}
          onClick={done}
          sx={{ color: "success.main" }}
          size="large"
        >
          <CheckCircle />
        </IconButton>
        {/* </Tooltip> */}

        <IconButton
          aria-label="close"
          onClick={() => clear()}
          color={"primary"}
          size="large"
        >
          <Cancel />
        </IconButton>
      </Toolbar>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  done: PropTypes.func.isRequired,
};

//////////////////////////////////////////////////

//*********Table Function*********

const PICKUP_QUERY = gqlb.query({
  operation: "listPickups",
  fields: [
    {
      operation: "data",
      fields: [
        "commissionAmountEditable",
        "id",
        "code",
        "date",
        "notes",
        "deliveryCommission",
        {
          customer: [
            "id",
            "name"
          ]
        },
      ],
      variables: {},
    },
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
      required: true,
    },
    page: {
      type: "Int",
    },
    input: {
      type: "ListPickupsFilterInput",
    },
  },
});

const PickupList = (props) => {
  const { type, variables, refetchPickupDataQuery } = props;

  const [pickups, setPickups] = useState([]);
  const [selectedPickups, setSelectedPickups] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [drawerState, setDrawerState] = useState(false);
  const [queryVariables, setQueryVariables] = useState({ refetch: true });
  const { refetch, ...restVariables } = queryVariables;
  const { enqueueSnackbar } = useSnackbar();

  const screenWidth = useWidth();

  const isScreenSmall = isWidthDown("xs", screenWidth);

  const { t } = useTranslation();
  const theme = useTheme();

  delete restVariables.page;

  const { data, loading } = useQuery(
    gql`
      ${PICKUP_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !variables.id,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        id: variables.id,
        input: {
          forPaymentId: variables.id,
          ...restVariables,
        },
      },
      onCompleted: (data) => {
        const handledData =
          data?.listPickups?.data !== null ? data?.listPickups?.data : [];

        let updatedDate = handledData.map((i) => {
          const selectedPickup = selectedPickups.find(
            (ele) => ele.pickup.id === i.id
          );
          if (selectedPickup) {
            return selectedPickup;
          } else {
            return {
              pickup: i,
              select: false,
            };
          }
        });
        setPickups(updatedDate);
      },
    }
  );

  const isPickupSelected = (addPickups, deletePickups) => {
    const mainPickups = addPickups
      ? [...addPickups]
      : [...selectedPickups];

    const filterPickups = deletePickups
      ? [...deletePickups]
      : [...selectedPickups];

    return mainPickups.filter((newPickup) => {
      return !filterPickups.some(
        (current) => current.pickup.id === newPickup.pickup.id
      );
    });
  };
  const [savePaymentEntriesMutation, { loading: savePaymentEntriesMutationLoad }] = useMutation(
    gql`
      ${SAVE_PAYMENT_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const onSaveEntries = (data) => {    
    const pickupIds = data.map(item => {
      return {
        id: item.pickup?.id,
        ...(["DLVBY"].includes(type) && { amount: item.amount }),
      }
    });
    
    pickupIds.length > 0 && savePaymentEntriesMutation({
      variables: {
        input: {
          paymentId: variables.id,
          pickups: pickupIds
        }
      }
    }).then(() => {
      enqueueSnackbar(t("saveSuccessful"), {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
      // collectionRefetch()
      refetchPickupDataQuery()
      props.done()
    }).catch((error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
    })
  }

  const handleSelectAllClick = (event, rows) => {
    const selected = pickups?.map((i) => {
      if (event.target.checked) {
        return {
          ...i,
          select: true,
        };
      } else {
        return {
          ...i,
          select: false,
        };
      }
    });

    if (event.target.checked) {
      const newPickups = isPickupSelected(selected);
      setSelectedPickups((prev) => [...prev, ...newPickups]);
    } else {
      const filteredPickups = isPickupSelected(null, selected);
      setSelectedPickups(filteredPickups);
    }
    setPickups(selected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const resetPage = () => setPage(0);

  const toggleFilterDrawer = () => setDrawerState(!drawerState);

  const updateSelectedPickupByForm = useCallback(
    ({ id, checkIsEmpty, name, value }) => {      
      const update = [...pickups];      
      const pickupIndex = update.findIndex((i) => {
        return i.pickup.id === id;
      });
      if (checkIsEmpty !== null || checkIsEmpty !== undefined)
        update[pickupIndex]["valid"] = checkIsEmpty;
      update[pickupIndex][name] = value;

      setPickups(update);
    },
    [pickups]
  );

  const filtersForm = (
    <PickupFiltersForm
      {...{ resetPage, loading }}
      preventPushUrl
      queryVariables={(variables) => setQueryVariables(variables)}
      onSubmitFunc={() => setDrawerState(false)}
      removeSearch={true}
      allowCode={true}
      filtersAllowed={
        [
          "date",
          "custm"
        ]
      }
    />
  );

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const selectedPickupLength = selectedPickups.length;

  let tableBody = null;

  let progress = (
    <Grid container item justifyContent="center" className={classes.track}>
      <CircularProgress />
    </Grid>
  );
  const selectedPickupParPage = () => {
    return pickups.filter((i) => i.select === true).length;
  };
  const validPickups = () => {
    return selectedPickupLength <= 0;
  };
  if (pickups && !loading) {
    tableBody = (
      <Grid container justifyContent="center" sx={{ width: "100%" }}>
        {/* *******Table******* */}
        <div className={classes.paper}>
          <TableFixedHeaderWraper containerScroll>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <EnhancedTableHead
                numSelected={selectedPickupParPage()}
                onSelectAllClick={(e) => handleSelectAllClick(e, pickups)}
                rowCount={pickups?.length}
                type={type}
              />
              <TableBody>
                {pickups?.map((pickup, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <CustomTableRow
                      key={pickup.pickup.id}
                      pickup={pickup}
                      type={type}
                      onChangeCheck={(check, selectedPickup) => {
                        setPickups((prev) => {
                          let updated = [...prev];
                          updated[index] = {
                            ...updated[index],
                            select: !updated[index].select,
                          };

                          if (check.target.checked) {
                            setSelectedPickups((prev) => [
                              ...prev,
                              updated[index],
                            ]);
                          } else {
                            setSelectedPickups((prev) => {
                              return prev.filter(
                                (i) => i.pickup.id !== pickup.pickup.id
                              );
                            });
                          }

                          return updated;
                        });
                      }}
                      labelId={labelId}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={data?.listPickups?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Grid>
    );
  }
  return (
    <PaymentPickupsProvider
      value={{ updateSelectedPickupByForm }}
    >
      <Root container justifyContent="center">
        <Backdrop
          sx={{ color: (theme) => theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={savePaymentEntriesMutationLoad}
          onClick={() => { }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {isScreenSmall ? (
          <SwipeableDrawer
            disableDiscovery={true}
            disableSwipeToOpen={true}
            anchor="left"
            open={drawerState}
            onClose={() => setDrawerState(false)}
            onOpen={() => setDrawerState(true)}
            classes={{ paper: classes.filters }}
            dir={theme.direction}
          >
            {filtersForm}
          </SwipeableDrawer>
        ) : (
          <Grid item sm={2}>
            {filtersForm}
          </Grid>
        )}
        <Grid item container sm={10} alignContent="flex-start">
          <EnhancedTableToolbar
            numSelected={selectedPickupLength}
            clear={props.done}
            toggleFilterDrawer={toggleFilterDrawer}
            done={() => {
              onSaveEntries(selectedPickups)
            }}
            disabled={validPickups()}
          />
          {loading
            ? progress
            : pickups && pickups?.length !== 0
              ? tableBody
              : !loading && (
                <EmptyTableMessage loading={false} message={t("noPickups")} />
              )}
        </Grid>
      </Root>
    </PaymentPickupsProvider>
  );
};

export default PickupList;
