import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useContext } from 'react'
import PropTypes from "prop-types";
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useHistory } from "react-router";
import config from "../../config.json";
import { UserLogout } from "../../helpers/helpersFunction";
import { ModeContext } from '../../Context/ModeContext';
import ButtonLoading from '../HOC/FunctionComponents/LoadingPages/ButtonLoading';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resetState } from '../../store/slices/preferences';

function LogoutDialog(props) {
    const {
        openLogout,
        setOpenLogout,
    } = props

    const closeDialog = () => {
        setOpenLogout(false);
    };
    const history = useHistory();
    const { t } = useTranslation();

    const LOGOUT = gql`
    mutation {
        logout
    }
    `;
    const landingPage = config.app.landingPage;
    const client = useApolloClient();
    const { setHasMessagesPermission } = useContext(ModeContext)
    const afterLogout = () => history.replace(landingPage ? "/" : "/login")


    const [logout, { loading: logoutLoad }] = useMutation(LOGOUT);
    const dispatch = useDispatch();

    const LogoutHandler = () => {
        logout()
            .then((res) => {
                if (res.data.logout === true) {
                    client.stop();
                    client.resetStore();
                    setHasMessagesPermission(false)
                    dispatch(resetState());
                    UserLogout(afterLogout);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <Dialog
            open={openLogout}
            onClose={closeDialog}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title" color={"text.primary"}>{t("logout")}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t("confirmLogout")}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={logoutLoad} onClick={closeDialog} color="primary">
                    {t("cancel")}
                </Button>
                <Button onClick={LogoutHandler} color="primary">
                    {logoutLoad ? <ButtonLoading /> : t("confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

LogoutDialog.prototype = {
    openLogout: PropTypes.bool.isRequired,
    setOpenLogout: PropTypes.func.isRequired,
}

export default LogoutDialog