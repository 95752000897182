import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";

export const REMOTE_DELIVERY_AGENT = gqlb.query({
    operation: "remoteDeliveryAgent",
    fields: [
        "name",
        "phone",
        "mobile",
    ],
    variables: {
        shipmentId: {
            type: "Int",
            required: true,
        },
    },
});


export const useRemoteDeliveryAgentData = ({ integration, shipmentId }) => {
    const { data,loading } = useQuery(
        gql`
          ${REMOTE_DELIVERY_AGENT.query}
        `,
        {
            variables: {
                shipmentId: parseInt(shipmentId)
            },
            skip: !integration,
            // notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
        }
    );

    const remoteDeliveryAgent = data?.remoteDeliveryAgent
    return {
        remoteDeliveryAgent,
        loading
    }
}