import { createSlice } from '@reduxjs/toolkit'
import config from '../../config.json'
import { extraReducersBuilder } from '../extraReducers/preferences';
const isDarkModeEnabled = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
const Languages = config.app.languages;

// Define the initial state
const initialState = {
    darkMode: {
        id: null,
        value: localStorage.getItem("darkMode") ? localStorage.getItem("darkMode") : isDarkModeEnabled ? "dark" : "light",
    },
    language: {
        id: null,
        value: localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : Languages[0],
    },
    exportInvoice: {
        id: null,
        value: [],
    },
    exportPaymentCUSTM: {
        id: null,
        value: []
    },
    exportPaymentDLVBY: {
        id: null,
        value: []
    },
    exportCollectionCUSTM: {
        id: null,
        value: []
    },
    exportCollectionDLVBY: {
        id: null,
        value: []
    },
    status: 'idle',
    error: null,
}

export const preferencesSlice = createSlice({
    name: 'preferences',
    initialState,
    reducers: {
        updateDarkMode: (state, action) => {
            localStorage.setItem("darkMode", action.payload.value)
            state.darkMode = action.payload;
        },
        updateLanguage: (state, action) => {
            localStorage.setItem("i18nextLng", action.payload.value)
            state.language = action.payload;
        },
        updateExportInvoice: (state, action) => {
            state.exportInvoice = action.payload;
        },
        updateExportPaymentCUSTM: (state, action) => {
            state.exportPaymentCUSTM = action.payload;
        },
        updateExportPaymentDLVBY: (state, action) => {
            state.exportPaymentDLVBY = action.payload;
        },
        updateExportCollectionCUSTM: (state, action) => {
            state.exportCollectionCUSTM = action.payload;
        },
        updateExportCollectionDLVBY: (state, action) => {
            state.exportCollectionDLVBY = action.payload;
        },
        resetState: () => {
            return {
                ...initialState,
                darkMode: {
                    ...initialState.darkMode,
                    value: localStorage.getItem("darkMode")
                        ? localStorage.getItem("darkMode")
                        : isDarkModeEnabled
                            ? "dark"
                            : "light",
                },
            };
        },
    },
    extraReducers: (builder) => {
        extraReducersBuilder(builder)
    },
})

export const {
    updateDarkMode,
    updateLanguage,
    resetState,
    updateExportInvoice,
    updateExportPaymentCUSTM,
    updateExportPaymentDLVBY,
    updateExportCollectionCUSTM,
    updateExportCollectionDLVBY
} = preferencesSlice.actions;

export default preferencesSlice.reducer
