import {
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import { Reorder } from "framer-motion";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import MUItextField from "../../HOC/MUI/MUItextField";
import { useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
export default function SelectColumView(props) {
  const {
    disabled,
    openViewColum,
    setOpenViewColum,
    localStorageName,
    initialData,
    setLocalDataKey,
    shipmentFields,
  } = props;

  const { handleSubmit, register, reset } = useForm();
  const [shipmentFieldsState, setShipmentFieldsState] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const isIcon = !Boolean(setOpenViewColum);

  useEffect(() => {
    const queryShipmentFields = [...shipmentFields];
    queryShipmentFields.forEach((ele, index) => {
      ele.id = ele.id || index + 1;
      ele.checked = ele.checked ?? true;
    });

    const localStorageViewShipment = localStorage.getItem(localStorageName);
    const parsedViewShipment = localStorageViewShipment
      ? JSON.parse(localStorageViewShipment)
      : initialData;
    const notCheckedFields = queryShipmentFields.filter((i) => {
      if (!parsedViewShipment?.includes(i.key)) {
        i.checked = false;
        return true;
      }
      return false;
    });

    const checkedFields = queryShipmentFields.filter((i) =>
      parsedViewShipment?.includes(i.key)
    );

    const sortedFields = checkedFields.sort(
      (a, b) =>
        parsedViewShipment?.indexOf(a.key) - parsedViewShipment?.indexOf(b.key)
    );
    setShipmentFieldsState([...sortedFields, ...notCheckedFields]);
  }, [openViewColum, shipmentFields, initialData, localStorageName]);
  const [dialogState, setDialogState] = useState(false);
  const fieldCount = shipmentFieldsState.length;
  const fieldSelected = shipmentFieldsState.filter((i) => i.checked).length;
  const onSelectAllClick = (e) => {
    const fields = [...shipmentFieldsState];
    fields.forEach((i) => {
      if (e.target.checked) {
        i.checked = true;
      } else {
        i.checked = false;
      }
    });
    setShipmentFieldsState(fields);
  };

  const onCloseDialog = () => {
    isIcon ? setDialogState(false) : setOpenViewColum(false);
    setSearchQuery("");
    reset({ keyword: "" });
  };

  const onOpenDialog = () => {
    setDialogState(true);
  };

  const handleReorder = (newOrder) => {
    setShipmentFieldsState((prevState) => {
      // Separate newOrder into checked and unchecked items
      const checkedItems = newOrder.filter((item) => item.checked);
      const uncheckedItems = newOrder.filter((item) => !item.checked);

      // Combine them: fixed fields first, followed by checked items, then unchecked items
      return [...checkedItems, ...uncheckedItems];
    });
  };

  const handleViewShipment = () => {
    const updatedFields = shipmentFieldsState.reduce((previous, current) => {
      if (current.checked) {
        previous.push(current.key);
      }
      return previous;
    }, []);
    localStorage.setItem(localStorageName, JSON.stringify(updatedFields));
    setLocalDataKey([]);
    setLocalDataKey(updatedFields);
    setSearchQuery("");
    reset({ keyword: "" });
    onCloseDialog();
  };
  const sortFieldsState = () => {
    setShipmentFieldsState((prevState) => {
      const checkedFields = prevState.filter((field) => field.checked);
      const uncheckedFields = prevState.filter((field) => !field.checked);
      return [...checkedFields, ...uncheckedFields];
    });
  };
  const filteredFieldsState = shipmentFieldsState.filter((field) =>
    t(field.label).toLowerCase().includes(searchQuery.toLowerCase())
  );

  const submit = () => {
    return {};
  };
  return (
    <>
      {isIcon && (
        <Tooltip title={t("exportExcel")}>
          <Box component="span">
            <IconButton disabled={disabled} onClick={onOpenDialog}>
              <Icon>sim_card_download</Icon>
            </IconButton>
          </Box>
        </Tooltip>
      )}
      <CustomDialog
        fullWidth
        maxWidth="xs"
        open={isIcon ? dialogState : openViewColum}
        onClose={onCloseDialog}
        title={
          <Fragment>
            <Checkbox
              edge="start"
              indeterminate={fieldSelected > 0 && fieldSelected < fieldCount}
              checked={fieldCount > 0 && fieldSelected === fieldCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all fields" }}
            />
            {t("viewColumns")}
            <form onSubmit={handleSubmit(submit)}>
              <MUItextField
                label={t("keyword")}
                margin="normal"
                autoFocus
                name={"keyword"}
                variant={"outlined"}
                register={register}
                InputProps={{
                  startAdornment: (
                    <SearchIcon sx={{ marginRight: 1 }} fontSize="medium" />
                  ),
                }}
                defaultValue=""
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </Fragment>
        }
        actions={
          <>
            <Button color="primary" onClick={onCloseDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={fieldSelected === 0}
              onClick={handleViewShipment}
            >
              {<ButtonLoading /> && t("save")}
            </Button>
          </>
        }
        content={
          <Fragment>
            <Reorder.Group
              axis="y"
              values={filteredFieldsState}
              onReorder={handleReorder}
              style={{ padding: "0px" }}
            >
              <List>
                {filteredFieldsState.map((field) => (
                  <Reorder.Item key={field.key} value={field}>
                    <CustomItemList
                      itemDetails={field}
                      state={shipmentFieldsState}
                      setState={setShipmentFieldsState}
                      sortFieldsState={sortFieldsState}
                    />
                  </Reorder.Item>
                ))}
              </List>
            </Reorder.Group>
          </Fragment>
        }
      />
    </>
  );
}

const CustomItemList = ({ itemDetails, state, setState, sortFieldsState }) => {
  const { label, checked } = itemDetails;
  const { t } = useTranslation();

  const handleToggle = () => {
    setState((prevState) =>
      prevState.map((item) =>
        item.key === itemDetails.key
          ? { ...item, checked: !item.checked }
          : item
      )
    );
    sortFieldsState();
  };

  return (
    <ListItem dense button sx={{ padding: "0px" }}>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked}
          onClick={handleToggle}
          tabIndex={-1}
          disableRipple
        />
      </ListItemIcon>
      <ListItemText primary={t(label)} />
    </ListItem>
  );
};
