import React from 'react';
import { Box, Link, Stack } from '@mui/material';
import { Globals } from '../Classes/Globals';
import { styled } from '@mui/material/styles';
const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  fontWeight: 500,
  "&:hover": {
    textDecoration: "underline",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));
const PhoneNumberActions = ({ mobile, phone, print, view }) => {
  const renderPhoneAction = (phoneNumber) => {
    const countryCode = Globals.settings.countryCode
    const allowPhoneKey = Globals.settings.allowPhoneKey
    const whatsappUrl = `https://wa.me/${!allowPhoneKey ? countryCode + phoneNumber : phoneNumber}`;
    return (
      <StyledLink href={whatsappUrl} target='_blank' ><span dir="ltr">
        {phoneNumber}
      </span></StyledLink>
    );
  };

  return print ?
    <Stack direction="column">
      <span span dir="ltr" style={{ display: "block" }}>
        {mobile}
      </span>
      <span dir="ltr">
        {phone}
      </span>
    </Stack> :
    <Stack
      direction={view ? { md: 'row', xs: 'column' } : { xs: 'column' }}
      spacing={view ? { md: 1 } : { xs: 0 }}
      flexWrap="wrap"
    >
      {mobile && renderPhoneAction(mobile, "1")}
      {
        view && <Box
          display="flex"
          alignItems="center"
        >/</Box>
      }
      {phone && renderPhoneAction(phone, "2")}

    </Stack>
};

export default PhoneNumberActions;
