import {
  Popover,
  List,
  ListItem,
  IconButton,
  Tooltip,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Accordion,
  AccordionSummary,
  Stack,
  Typography,
  AccordionDetails,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGES_DETAILS } from "../../../helpers/LanguagesVariables";
import config from "../../../config.json";

import { useApolloClient } from "@apollo/client";
import LanguageIcon from '@mui/icons-material/Language';
import { styled } from '@mui/material/styles';
import { ExpandMore } from "@mui/icons-material";
import { MdLanguage } from "react-icons/md";
import { Globals } from "../Classes/Globals";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../store/store";
import { updateLanguage } from "../../../store/slices/preferences";
import { updateLanguageAsync } from "../../../store/thunk/preferencesThunks";

const PREFIX = 'language';

const classes = {
  list: `${PREFIX}-list`,
  menuItemAccordion: `${PREFIX}-menuItemAccordion`,
  accordion: `${PREFIX}-accordion`,
  accordionSummary: `${PREFIX}-accordionSummary`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(MenuItem)((
  {
    theme
  }
) => ({
  [`&.${classes.menuItemAccordion}`]: {
    padding: 0,
    margin: "4px 0"
  },
  [`& .${classes.accordion}`]: {
    background: "none",
    width: "100%",
    boxShadow: "none",
  },
  [`& .${classes.accordionSummary}`]: {
    minHeight: "16px !important",
    padding: "0 3px",
    [`& .Mui-expanded`]: {
      margin: 0
    },
    [`& .MuiAccordionSummary-content`]: {
      margin: "6px 0"
    },
  },
}));


const LanguageIcons = ({ listItem, handleCloseMenu }) => {
  const { t, i18n } = useTranslation();
  const client = useApolloClient();
  const dispatch = useDispatch()

  const language = useAppSelector((state) => state.preferences.language);

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    document.getElementsByTagName("html")[0].setAttribute("dir", i18n.language === "ku" ? "rtl" : i18n.dir());
    client.reFetchObservableQueries();

    dispatch(updateLanguage({
      id: language.id,
      value: event.target.value
    }))

    if (Globals?.user) {
      dispatch(updateLanguageAsync(
        {
          id: language.id,
          value: event.target.value
        }
      ))
    }

    if (!!handleCloseMenu) {
      handleCloseMenu()
    } else {
      handleCloseLang()
    }
  };

  const [anchorElLang, setAnchorElLang] = useState(null);
  const openLang = Boolean(anchorElLang);
  const langId = openLang ? "popover" : undefined;

  const handleOpenLang = (event, id) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLang = () => {
    setAnchorElLang(null);
  };

  if (config.app.languages.length === 1) {
    return null
  }


  const listItemData = <Root className={classes.menuItemAccordion}>
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionSummary}
      >
        <Stack spacing={2} direction="row" alignItems="center" color={"text.secondary"}>
          <MdLanguage fontSize={20} />
          <Typography noWrap color={"text.primary"}>{t("chooseLang")}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={i18n.language}
          onChange={changeLanguage}
        >
          {config.app.languages.map((lang, index) => {
            return (
              <Typography key={index}>
                <FormControlLabel
                  checked={lang === i18n.language}
                  value={lang}
                  control={<Radio />}
                  label={LANGUAGES_DETAILS[lang].nativeName} />
              </Typography>
            )
          })}
        </RadioGroup>
      </AccordionDetails>
    </Accordion>
  </Root>

  const defaultData = <Fragment>
    <Tooltip title={t("chooseLang")}>
      <IconButton
        sx={{ p: 1 }}
        aria-label="Language"
        onClick={handleOpenLang}
        aria-describedby={langId}
      // disabled={shipmentsId?.length === 0}
      >
        <LanguageIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
    <Popover
      id={langId}
      open={openLang}
      anchorEl={anchorElLang}
      onClose={handleCloseLang}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <List>
        <Box>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={i18n.language}
            onChange={changeLanguage}
          >
            {config.app.languages.map((lang, index) => {
              return (
                <ListItem className={classes.list} key={index}>
                  <FormControlLabel
                    checked={lang === i18n.language}
                    value={lang}
                    control={<Radio />}
                    label={LANGUAGES_DETAILS[lang].nativeName} />
                </ListItem>
              )
            })}
          </RadioGroup>
        </Box>
      </List>
    </Popover>
  </Fragment>

  return listItem ? listItemData : defaultData
};

export default LanguageIcons;
